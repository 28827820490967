<template>
  <h1 style="color: black; margin-top: 2rem;">Taxonomy Classification</h1>
  <div class="line" style="width: 60px; height: 4px; background-color: var(--primary);margin-bottom: 0.5rem;"></div>
  <span style="margin-bottom: 1rem; color: grey;">The clickable taxonomy below provides an overview of the classification structure, helping you understand how ecosystem actors are grouped.</span>
  <div class="taxonomy-navigator">
    <div class="taxonomy-navigation">
      <template v-for="(child, taxonomy) in taxonomyData" :key="taxonomy">
        <DsButton
          class="taxonomy-navigation-item"
          @click="setSelectedTaxonomy(taxonomy, child)"
          :variant="selectedTaxonomy === taxonomy ? 'rounded-secondary' : 'rounded'"
          size="small"
          :label="taxonomy"
          v-if="visibleTaxonomies.includes(taxonomy)"
          style="width: 100%; background-color: #ee7478; color: white;"
          :style="{backgroundColor: isActive(taxonomy) ? '#c43a3d' : '#ee7478'}"
        />
      </template>
    </div>
    <div ref="childrenWrapper" class="taxonomy-children-wrapper" style="padding: 0.25rem;">
      <div ref="childrenContainer" class="taxonomy-children" style="margin-left: 1rem;">
        <div v-for="child in childs" :key="child.id" class="taxonomy-child">
          <div v-if="!child.parent_id || !child.industry_id || !child.domain_id">
            <DsButton
              variant="rounded-secondary"
              size="small"
              :label="child.name"
              @click="setSubChilds(child)"
              style="background-color: #c8d8d2; color: black;"
              :style="{backgroundColor: isActive(child.name) ? '#8D9894FF' : '#c8d8d2'}"
            />
          </div>
          <div v-else-if="child.children && child.children.length > 0">
            <div v-for="childTaxonomy in child.children" :key="childTaxonomy.id">
              <DsButton
                variant="link"
                size="small"
                :label="childTaxonomy.name"
                @click="setSubChilds(child)"
                style="background-color: #c8d8d2; color: black;"
                :style="{backgroundColor: isActive(childTaxonomy.name) ? '#8D9894FF' : '#c8d8d2'}"
              />
              <!--                @click="setByFacet(selectedTaxonomy, childTaxonomy)"-->
            </div>
          </div>
          <div v-else-if="child.industries && child.industries.length > 0">
            <div v-for="childTaxonomy in child.industries" :key="childTaxonomy.id">
              <DsButton
                variant="rounded-secondary"
                size="small"
                :label="childTaxonomy.name"
                style="background-color: #c8d8d2; color: black;"
                @click="setSubChilds(child)"
                :style="{backgroundColor: isActive(childTaxonomy.name) ? '#8D9894FF' : '#c8d8d2'}"
              />
              <!--                @click="setByFacet(selectedTaxonomy, childTaxonomy)"-->
            </div>
          </div>
          <div v-else-if="child.domains && child.domains.length > 0">
            <div v-for="childTaxonomy in child.domains" :key="childTaxonomy.id">
              <DsButton
                variant="rounded-secondary"
                size="small"
                :label="childTaxonomy.name"
                style="background-color: #c8d8d2; color: black;"
                :style="{backgroundColor: isActive(childTaxonomy.name) ? '#8D9894FF' : '#c8d8d2'}"
              />
              <!--                @click="setByFacet(selectedTaxonomy, childTaxonomy)"-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="taxonomy-children">
      <div v-for="childTaxonomy in subChilds" :key="childTaxonomy.id">
        <DsButton
          class="taxonomy-child"
          variant="rounded-secondary"
          size="small"
          :label="childTaxonomy.name"
          @click="showDescription(childTaxonomy)"
          style="background-color: #e9d9ca; color: black;"
          :style="{backgroundColor: isActive(childTaxonomy.name) ? '#efc49b' : '#e9d9ca'}"
        />
      </div>
    </div>
    <div class="taxonomy-description-container" style="align-content: center; max-height: 80vh; width: 50%;" v-if="selectedTaxonomyForDescription">
      <h1 style="text-align: center; margin-bottom: 2rem;">{{ selectedTaxonomyForDescription }}</h1>
      <p style="text-align: center; width: 80%; margin: 0 auto;">{{ taxonomyDescription }}</p>

      <!--      <DsButton
              class="taxonomy-child"
              variant="rounded-secondary"
              size="small"
              label="View Actors"
              @click="setByFacet(selectedTaxonomy, selectedTaxonomyObject)"
              style="background-color: #e9d9ca; color: black; justify-content: center; align-self: center; margin: 1rem auto; width: fit-content;"
            />-->
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from "@/store/modules/filters";
  import UserMixin from "@/util/UserMixin";

  export default defineComponent({
    name: "TaxonomyNavigator",
    mixins: [UserMixin],
    props: {
      visibleTaxonomies: {
        type: Array,
        required: false,
        default: () => ["categories", "memberships", "domains"],
      },
    },
    data() {
      return {
        taxonomyData: {},
        selectedTaxonomy: "",
        childs: [],
        subChilds: [],
        selectedTaxonomyForDescription: null,
        taxonomyDescription: "",
        selectedChildTaxonomy: null,
        selectedTaxonomyObject: null,
      };
    },
    mounted() {
      this.getAllTaxonomies();
    },
    methods: {
      isActive(taxonomy: any) {
        return this.selectedTaxonomy === taxonomy || this.selectedTaxonomyForDescription === taxonomy || this.selectedChildTaxonomy === taxonomy
      },
      getBackgroundColorForTaxonomy(taxonomy) {
        return this.$store.state.config.hexColours[taxonomy.hex]
      },
      showDescription(child) {
        this.selectedTaxonomyObject = child
        this.selectedTaxonomyForDescription = child.name
        this.taxonomyDescription = child.description
      },
      setSubChilds(child) {
        let taxonomy = []

        this.selectedChildTaxonomy = child.name
        this.selectedTaxonomyForDescription = null
        this.selectedTaxonomyObject = null
        this.taxonomyDescription = ''

        if (child && child.domains) {
          taxonomy = child.domains
        }

        this.subChilds = taxonomy

        this.showDescription(child)
      },
      normalizeFacet(facet) {
        const mapping = {
          technologies: "technology",
          industries: "industries",
          categories: "category",
          activities: "activities",
          memberships: "membership",
          domains: "domains",
        };
        return mapping[facet] || "";
      },
      setByFacet(facet, option) {
        const facets = ["technology", "industries", "category", "activities", "membership", "domains"];
        facets.forEach(f => this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_BY_FACET, f));

        this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
          facet: this.normalizeFacet(facet),
          values: [Number(option.id) || Number(option.value)],
        });

        this.$router.push(this.isMember ? "/actors" : "/actors-simplified");
      },
      setSelectedTaxonomy(taxonomy, childs) {
        this.selectedTaxonomy = taxonomy;
        this.childs = childs;
        this.subChilds = [];
        this.selectedTaxonomyForDescription = null
        this.taxonomyDescription = ''
      },
      getAllTaxonomies() {
        this.taxonomyData = this.getHardcodedTaxonomies()
        const initialTaxonomy = this.visibleTaxonomies[0];
        this.selectedTaxonomy = initialTaxonomy;
        this.childs = this.taxonomyData[initialTaxonomy] || [];
        /*getAllTaxonomies()
          .then((response) => {
            this.taxonomyData = response;
            const initialTaxonomy = this.visibleTaxonomies[0];
            this.selectedTaxonomy = initialTaxonomy;
            this.childs = response[initialTaxonomy] || [];
          })
          .catch((error) => {
            console.error("Failed to fetch taxonomies:", error);
          });*/
      },
      getHardcodedTaxonomies() {
        return {
          categories: [
            {
              id: 1,
              name: 'Academia',
              description: 'The life, community, or world of teachers, schools, and education. Active in education and academic research.',
            },
            {
              id: 1,
              name: 'Association',
              description: 'A group of people organized for a joint purpose. \n' +
                '\n' +
                'BioTechNZ (Health & FFA) ',
            },
            {
              id: 1,
              name: 'Community',
              description: 'Informal grouping of people without fixed structure e.g. a meetup, a (popular) movement. \n' +
                '\n' +
                'Canterbury Tech',
            },
            {
              id: 1,
              name: 'Enabler',
              description: 'Something or someone that makes it possible for a particular thing to happen or be done. Plays an enabling role in the ecosystem by providing services (e.g. ecosystem orchestrator, match making) or logistics (e.g. co-working).',
            },
            {
              id: 1,
              name: 'Event',
              description: 'Something that happens or takes place, especially one of importance, a planned public or social occasion.',
            },
            {
              id: 1,
              name: 'Federation',
              description: 'A group of corporations collaborating based upon a specific characteristic of the group members e.g. an industry, a geographical area. \n' +
                '\n' +
                'Do we need a split between Association & Federation / PPP / Grower Group',
            },
            {
              id: 1,
              name: 'Hospital',
              description: 'A hospital is a health care institution providing patient treatment with specialized medical and nursing staff and medical equipment. \n' +
                '\n' +
                'Keep hospital in all  ',
            },
            {
              id: 1,
              name: 'Incubator / Accelerator',
              description: 'A business incubator is a workspace created to offer startups and new ventures access to the resources they need, all under one roof. ... Most incubators are created as temporary launching pads for new businesses, with the expectation that participants will eventually graduate and move out. A business accelerator is a program that gives developing companies access to mentorship, investors and other support that help them become stable, self-sufficient businesses. ... Less developed companies not ready for an accelerator would instead use a business incubator for support.',
            },
            {
              id: 1,
              name: 'Investment Firm / Fund',
              description: 'An investment company is a corporation or trust engaged in the business of investing the pooled capital of investors in financial securities. Targets can be any kind of company. Not specialising in additional services or logistics.',
            },
            {
              id: 1,
              name: 'Knowledge Institute',
              description: 'A knowledge institute (also research institute) aims to gather and analyse available data, engage in experimentation based on theories inspired by analysis and report the outcomes of those experiments to interested parties.',
            },
            {
              id: 1,
              name: 'Manufacturer / Producer',
              description: 'Manufacturers typically produce ingredients, semi-finished or finished products. They can use technologies developed by Technology Providers',
            },
            {
              id: 1,
              name: 'NGO',
              description: 'A non-governmental organization (NGO) is a non-profit group that functions independently of any government. NGOs, sometimes called civil societies, are organized on community, national and international levels to serve a social or political goal such as humanitarian causes or the environment.',
            },
            {
              id: 1,
              name: 'Market Place / Platform',
              description: 'A range of services available on the Internet including marketplaces, search engines, social media, creative content outlets, app stores, communications services, payment systems, services comprising the so-called “collaborative” or “gig” economy, and much more.',
            },
            {
              id: 1,
              name: 'Project Team',
              description: 'A loose organisation of actors around an official project that is limited in time and/or scope.',
            },
            {
              id: 1,
              name: 'Public Agency',
              description: 'A government or state agency, sometimes an appointed commission, is a permanent or semi-permanent organization in the machinery of government that is responsible for the oversight and administration of specific functions, such as an administration. \n' +
                '\n' +
                'Do we need a split between Central Gov Agency & Regional Gov Agency \n' +
                '\n' +
                'Venture Timaru',
            },
            {
              id: 1,
              name: 'Public Private Partnership',
              description: '',
            },
            {
              id: 1,
              name: 'Publisher',
              description: 'The business or profession of the commercial production and issuance of literature, information, musical scores or sometimes recordings, or art',
            },
            {
              id: 1,
              name: 'Retailer',
              description: 'A business that sells goods to the public in relatively small quantities for use or consumption rather than for resale.',
            },
            {
              id: 1,
              name: 'Service Provider',
              description: 'A service provider provides organizations with services like consulting, legal, real estate, communications, storage, processing.',
            },
            {
              id: 1,
              name: 'Technology Company',
              description: 'A company that develops, produces and sells software applications and/or technology that is used in the business or manufacturing processes of the client. Typical technologies are “new” technologies like wearables, Internet of Things, machine learning, sensors, augmented reality…'
            }
          ],
          memberships: [
            {
              id: 1,
              name: 'Traditional Industry Players',
              description: 'Established companies and organizations that form the backbone of the current ecosystem. They provide stability and resources. They act as potential partners, customers, or acquirers of innovation. \n' +
                '\n' +
                'Large corporations, SMEs, and legacy players in the core industry. \n' +
                '\n' +
                'Suppliers, manufacturers, and distributors integral to the existing value chain.',
            },
            {
              id: 2,
              name: 'Startups & Scaleups',
              description: 'Emerging companies with high growth potential, often focusing on innovative or disruptive solutions. They drive innovation and disruption by testing and validating new business models, products, or technologies. \n' +
                '\n' +
                ' ',
            },
            {
              id: 3,
              name: 'Adjacent Industry Players',
              description: 'Organizations outside the immediate industry but with potential synergies or crossover applications. They enable cross-pollination of ideas and technology transfer. They might open new market opportunities and value chains.',
            },
            {
              id: 4,
              name: 'Innovation Ecosystem',
              description: 'Entities and organizations that facilitate, support, or enable innovation within the ecosystem. They act as catalysts for innovation and collaboration. They provide funding, infrastructure, and expertise. \n' +
                '\n' +
                'Research institutions, universities, and labs. \n' +
                '\n' +
                'Incubators, accelerators, and venture capital firms. \n' +
                '\n' +
                'Policy makers, economic development agencies, and innovation hubs.',
            },
            {
              id: 5,
              name: 'Public agencies',
              description: 'Government bodies, regulatory agencies, and public funders are often critical to enabling or influencing innovation ecosystems.',
            },
            {
              id: 6,
              name: 'Enablers',
              description: 'Consultants, digital platform providers, or infrastructure developers that serve as operational enablers.',
            }
          ],
          domains: [
            {
              id: 1,
              name: 'Aerospace – OECD taxonomies',
              description: '',
            },
          ],
        }
      },
    },
  });
</script>

<style scoped>
  .taxonomy-children-wrapper {
    transition: height 0.3s ease;
    max-height: 60vh;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .taxonomy-children-wrapper::-webkit-scrollbar {
    display: none;
  }

  .taxonomy-navigator {
    margin: 20px auto;
    font-family: Arial, sans-serif;
    border-radius: 0.5rem;
    display: flex;
  }

  .taxonomy-navigation {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 0.25rem;
  }

  .taxonomy-navigation-item {
    margin-bottom: 1rem;
  }

  .taxonomy-children {
    display: flex;
    flex-direction: column;
  }

  .taxonomy-child {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  .taxonomy-description-container {
    display: flex;
    flex-direction: column;
    margin: 4rem;
  }
</style>
