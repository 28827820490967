<template>
  <div class="top-filters" v-if="displayFilters">
    <CategorizedSearchInput
      :placeholder="displayedKeywords.length > 0 ? '' : 'Search on keyword, locations, tags, ...'"
      layout="large"
      :categories="searchInputCategories"
      :renderOption="(option) => option.display"
      :displayedKeywords="displayedKeywords"
      @changeQuery="handleSearchInputQueryChange"
      @selectOption="handleAddKeyword"
      @removeKeyword="handleRemoveKeyword"
      @clearKeywords="clearAllFilters"
    />
    <div class="top-filters__filters-section">
      <table style="width:100%" v-if="hasFilterControls.length > 0">
        <tbody>
        <tr>
          <td>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('category')">
              <filters-dropdown
                :options="showCategoryBasedOnActiveFilters"
                :label="getTaxonomyAlias('categories', false, 'Category')"
                variant="minimal"
                v-model="categoryFilter"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('membership')">
              <filters-dropdown
                :options="membershipOptions"
                :label="getTaxonomyAlias('memberships', false, 'Memberships')"
                variant="minimal"
                v-model="membershipFilter"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('stage')">
              <filters-dropdown
                :options="stageOptions"
                :label="getTaxonomyAlias('stages', false, 'Growth Stages')"
                variant="minimal"
                v-model="stageFilter"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('activities')">
              <filters-dropdown
                :options="activityOptions"
                :label="getTaxonomyAlias('activities')"
                variant="minimal"
                v-model="activitiesFilter"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('sustainability_goal')">
              <filters-dropdown
                dropdownSize="large"
                :options="sustainabilityGoals"
                label="Sustainability Goals"
                v-model="sustainabilityGoalFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('industries')">
              <hierarchy-dropdown
                :options="industriesOptions"
                :label="getTaxonomyAlias('industries')"
                v-model="industriesFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('domains')">
              <hierarchy-dropdown
                :options="domainsOptions"
                :label="getTaxonomyAlias('domains')"
                v-model="domainsFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('product_features_a')">
              <hierarchy-dropdown
                :options="productFeaturesAOptions"
                :label="getTaxonomyAlias('productFeaturesA')"
                v-model="productFeaturesAFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('product_features_b')">
              <hierarchy-dropdown
                :options="productFeaturesBOptions"
                :label="getTaxonomyAlias('productFeaturesB')"
                v-model="productFeaturesBFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('product_features_c')">
              <hierarchy-dropdown
                :options="productFeaturesCOptions"
                :label="getTaxonomyAlias('productFeaturesC')"
                v-model="productFeaturesCFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('technology')">
              <!-- <filters-dropdown
               :options="technologyOptions"
               :label="getTaxonomyAlias('technology')"
               v-model="technologyFilter"
               variant="minimal"
               />-->
              <hierarchy-dropdown
                :options="technologyOptions"
                :label="getTaxonomyAlias('technology')"
                v-model="technologyFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('maturity')">
              <filters-dropdown
                :options="maturityOptions"
                label="Maturity"
                v-model="maturityFilter"
                variant="minimal"
              />
            </div>
            <div class="top-filters__filter-containers" v-if="displayedFilters.includes('type')">
              <filters-dropdown
                :options="businessTypeOptions"
                label="Type"
                v-model="typeFilter"
                variant="minimal"
              />
            </div>
          </td>
          <td style="width:66px">
            <div class="top-filters__show-more-filters">
              <a @click="showAllFilters">More filters</a>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
  import debounce from 'lodash/debounce.js'

  import FiltersDropdown from '../Dropdown/FiltersDropdown.vue'
  import HierarchyDropdown from '../Dropdown/HierarchyDropdown.vue'
  import Keyword from '../Keyword/Keyword.vue'
  import Slider from '../Slider/Slider.vue'
  import SliderFoundingDate from '../Slider/SliderFoundingDate.vue'
  import SliderFundingDate from '../Slider/SliderFundingDate.vue'
  import KeywordSuggestionSearchBar from '../SearchBar/KeywordSuggestionSearchBar.vue'
  import CategorizedSearchInput from '../CategorizedSearchInput/CategorizedSearchInput.vue'

  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES, } from '../../store/modules/filters.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'

  import * as keywordsApi from '../../api/keywords.js'
  import { toTwoLevelTaxonomyId } from '../../util/hierarchy-taxonomy.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { sustainabilityGoals } from '../../constants/config.js'

  import FiltersMixin from '../../util/FiltersMixin.js'
  import ConfigMixin from '../../util/ConfigMixin.js'
  import KeywordLabelMixin from '../../util/KeywordLabelMixin.js'

  import { countryFocus, getFacetValue, mentionsFilter } from './Filters.js'
  import { getKeywordsFromState, getReportFieldById, } from '../../util/helpers.js'
  import { handleRemoveNestedKeyword, handleRemoveReportKeyword, } from '../../util/helpers-filters.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'top-filter',
    props: {
      onlyShowProductFilters: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        searchInputCategories: [],
        legendSelection: [],
        membershipSelection: [],
        stagesSelection: [],
        marketSelection: [],
        industrySelection: [],
        technologySelection: [],
        activitiesSelection: [],
        categorySelection: [],
        sustainabilityGoalSelection: [],
        domainSelection: [],
        officeTypeSelection: [],
        typeSelection: [],
        maturitySelection: [],
        defaultSelection: {
          legends: true,
          membership: true,
          stages: true,
        },
        numberOfVisibleFilters: 7,
        sustainabilityGoals,
      }
    },
    computed: {
      showCategoryBasedOnActiveFilters() {
        let filters = []

        const actorTypes = this.$store.state.filters.actorTypes

        if (actorTypes.includes('LegalEntity')) {
          filters.push(...this.categoryValuesAndLabelsPerType.LegalEntity)
        }

        if (actorTypes.includes('Person')) {
          filters.push(...this.categoryValuesAndLabelsPerType.Person)
        }

        if (actorTypes.includes('Product')) {
          filters.push(...this.categoryValuesAndLabelsPerType.Product)
        }

        return filters
      },
      config() {
        return this.$store.state.config
      },
      displayFilters() {
        return this.$store.state.filters.filter.visible
      },
      hasFilterControls() {
        const filterControlsList = ['category', 'membership', 'stage', 'activities', 'industries', 'technology', 'domains', 'maturity']

        if (!this.config.filterControls) {
          return []
        }

        return this.config.filterControls.filter(item => filterControlsList.indexOf(item) > -1)
      },
      keywords() {
        return this.$store.state.filters.keywords
      },
      reportFilterKeywords() {
        var reportFilters = this.$store.state.filters.reportFilters

        if (!reportFilters) {
          return []
        }

        var displayableReportFilters = []

        Object.keys(reportFilters).forEach((reportFieldIdentifier) => {
          var reportId = reportFieldIdentifier.replace('report_field_', '')
          var reportField = getReportFieldById(reportId)

          if (reportField && ['multi_select', 'checkbox', 'options'].includes(reportField.type) && reportFilters[reportFieldIdentifier] && reportFilters[reportFieldIdentifier].length > 0) {
            reportFilters[reportFieldIdentifier].forEach((option) => {
              displayableReportFilters.push({
                value: option,
                facet: reportFieldIdentifier,
                type: 'report',
              })
            })
          }
        })

        return displayableReportFilters
      },
      hiddenFacets() {
        return this.enabled.concat(this.legendProperty)
      },
      activeKeywords() {
        return this.$store.getters.activeKeywords.filter(k => !this.hiddenFacets.includes(k.facet) && k.value !== 'LegalEntity' && k.facet !== 'exclude_twitter')
      },
      hiddenTwitterHandles() {
        return this.$store.getters.activeKeywords.filter(k => k.facet === 'exclude_twitter')
      },
      displayedFilters() {
        const newDisplayedFilters = []

        this.visibleFilters.forEach(item => {
          const filterControlsList = ['category', 'membership', 'stage', 'activities', 'industries', 'technology', 'domains', 'maturity', 'product_features_a', 'product_features_b', 'product_features_c']

          if (newDisplayedFilters.length <= this.numberOfVisibleFilters && filterControlsList.includes(item)) {
            newDisplayedFilters.push(item)
          }
        })

        return newDisplayedFilters
      },
      activeMemberships() {
        return this.keywords
          .filter(k => k.facet === 'membership')
          .map(k => k.value)
          .concat('')
      },
      activeStages() {
        return this.keywords
          .filter(k => k.facet === 'stage')
          .map(k => k.value)
          .concat('')
      },
      mapBoundsEnabled() {
        return this.$store.state.filters.mapBounds.tl || this.$store.state.filters.mapBounds.br
      },
      domainsFilter: {
        get() {
          return this.keywords
            .filter(k => k.facet === 'domains')
            .map(k => k.value)
            .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.domains, 'domains'))
        },
        set(values) {
          this.domainSelection = this.keywords.filter(k => k.facet === 'domains').map(k => k.value)
          const domainSelectionIndex = this.domainSelection.indexOf(values)
          if (domainSelectionIndex > -1) {
            this.domainSelection.splice(domainSelectionIndex, 1)
          } else {
            this.domainSelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'domains',
            values: this.domainSelection,
          })
        },
      },
      productFeaturesAFilter: {
        get() {
          return this.keywords
            .filter(k => k.facet === 'product_features_a')
            .map(k => k.value)
            .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.productFeaturesA, 'children'))
        },
        set(values) {
          this.productFeatureASelection = this.keywords.filter(k => k.facet === 'product_features_a').map(k => k.value)
          const productFeatureASelectionIndex = this.productFeatureASelection.indexOf(values)
          if (productFeatureASelectionIndex > -1) {
            this.productFeatureASelection.splice(productFeatureASelectionIndex, 1)
          } else {
            this.productFeatureASelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'product_features_a',
            values: this.productFeatureASelection,
          })
        },
      },
      productFeaturesBFilter: {
        get() {
          return this.keywords
            .filter(k => k.facet === 'product_features_b')
            .map(k => k.value)
            .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.productFeaturesB, 'children'))
        },
        set(values) {
          this.productFeatureBSelection = this.keywords.filter(k => k.facet === 'product_features_b').map(k => k.value)
          const productFeatureBSelectionIndex = this.productFeatureBSelection.indexOf(values)
          if (productFeatureBSelectionIndex > -1) {
            this.productFeatureBSelection.splice(productFeatureBSelectionIndex, 1)
          } else {
            this.productFeatureBSelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'product_features_b',
            values: this.productFeatureBSelection,
          })
        },
      },
      productFeaturesCFilter: {
        get() {
          return this.keywords
            .filter(k => k.facet === 'product_features_c')
            .map(k => k.value)
            .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.productFeaturesC, 'children'))
        },
        set(values) {
          this.productFeatureCSelection = this.keywords.filter(k => k.facet === 'product_features_c').map(k => k.value)
          const productFeatureCSelectionIndex = this.productFeatureCSelection.indexOf(values)
          if (productFeatureCSelectionIndex > -1) {
            this.productFeatureCSelection.splice(productFeatureCSelectionIndex, 1)
          } else {
            this.productFeatureCSelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'product_features_c',
            values: this.productFeatureCSelection,
          })
        },
      },
      industriesFilter: {
        get() {
          return this.$store.getters.selectedIndustries
        },
        set(values) {
          this.industrySelection = this.$store.getters.selectedIndustries.slice()
          const index = this.industrySelection.indexOf(values)
          if (index > -1) {
            this.industrySelection.splice(index, 1)
          } else {
            this.industrySelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'industries',
            values: this.industrySelection,
          })
        },
      },
      technologyFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'technology').map(k => k.value)
        },
        set(values) {
          this.technologySelection = this.keywords.filter(k => k.facet === 'technology').map(k => k.value)
          const index = this.technologySelection.indexOf(values)
          if (index > -1) {
            this.technologySelection.splice(index, 1)
          } else {
            this.technologySelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'technology',
            values: this.technologySelection,
          })
        },
      },
      activitiesFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'activities').map(k => k.value)
        },
        set(values) {
          this.activitiesSelection = this.keywords.filter(k => k.facet === 'activities').map(k => k.value)
          const index = this.activitiesSelection.indexOf(values)

          if (index > -1) {
            this.activitiesSelection.splice(index, 1)
          } else {
            this.activitiesSelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'activities',
            values: this.activitiesSelection,
          })
        },
      },
      membershipFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'membership').map(k => k.value)
        },
        set(values) {
          this.membershipSelection = this.keywords.filter(k => k.facet === 'membership').map(k => k.value)
          const index = this.membershipSelection.indexOf(values)

          if (index > -1) {
            this.membershipSelection.splice(index, 1)
          } else {
            this.membershipSelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'membership',
            values: this.membershipSelection,
          })
        },
      },
      categoryFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'category').map(k => k.value)
        },
        set(values) {
          this.categorySelection = this.keywords.filter(k => k.facet === 'category').map(k => k.value)
          const index = this.categorySelection.indexOf(values)

          if (index > -1) {
            this.categorySelection.splice(index, 1)
          } else {
            this.categorySelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'category',
            values: this.categorySelection,
          })
        },
      },
      stageFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'stage').map(k => k.value)
        },
        set(values) {
          this.stageSelection = this.keywords.filter(k => k.facet === 'stage').map(k => k.value)
          const index = this.stageSelection.indexOf(values)

          if (index > -1) {
            this.stageSelection.splice(index, 1)
          } else {
            this.stageSelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'stage',
            values: this.stageSelection,
          })
        },
      },
      sustainabilityGoalFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'sustainability_goal').map(k => k.value)
        },
        set(values) {
          this.sustainabilityGoalSelection = this.keywords.filter(k => k.facet === 'sustainability_goal').map(k => k.value)
          const index = this.sustainabilityGoalSelection.indexOf(values)

          if (index > -1) {
            this.sustainabilityGoalSelection.splice(index, 1)
          } else {
            this.sustainabilityGoalSelection.push(values)
          }

          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'sustainability_goal',
            values: this.sustainabilityGoalSelection,
          })
        },
      },
      typeFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'type').map(k => k.value)
        },
        set(values) {
          this.typeSelection = this.keywords.filter(k => k.facet === 'type').map(k => k.value)
          const index = this.typeSelection.indexOf(values)
          if (index > -1) {
            this.typeSelection.splice(index, 1)
          } else {
            this.typeSelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'type',
            values: this.typeSelection,
          })
        },
      },
      maturityFilter: {
        get() {
          return this.keywords.filter(k => k.facet === 'maturity').map(k => k.value)
        },
        set(values) {
          this.maturitySelection = this.keywords.filter(k => k.facet === 'maturity').map(k => k.value)
          const index = this.maturitySelection.indexOf(values)
          if (index > -1) {
            this.maturitySelection.splice(index, 1)
          } else {
            this.maturitySelection.push(values)
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: 'maturity',
            values: this.maturitySelection,
          })
        },
      },
      displayedKeywords() {
        // Return all filters that can be displayed in the search input
        let displayedKeywords = []
        let simpleKeywords = this.keywords || []

        simpleKeywords = simpleKeywords.filter((keyword) => {
          return keyword.value !== '-' &&
            keyword.facet !== 'actor_type' &&
            keyword.facet !== 'legendProperty' &&
            keyword.facet !== 'mentionsMin' &&
            keyword.facet !== 'mentionsMax' &&
            !keyword.facet.includes('report_field_')
        })

        displayedKeywords = displayedKeywords.concat(simpleKeywords)
        getKeywordsFromState(displayedKeywords, this.$store, this.filterableReportFields)

        return displayedKeywords
      },
      countryFocus,
      mentionsFilter,
    },
    methods: {
      handleSearchInputQueryChange: debounce(async function (value) {
        if (value.length < 2) {
          return
        }

        const response = await keywordsApi.getKeywordsForValue(value, '', this.onlyShowProductFilters)
        const keywordsByFacet = response.reduce((map, keyword) => {
          if (!map[keyword.facet]) {
            map[keyword.facet] = [keyword]
          } else {
            map[keyword.facet] = [...map[keyword.facet], keyword]
          }

          return map
        }, {})

        this.searchInputCategories = Object.entries(keywordsByFacet)
          .map(([facetName, keywords]) => ({
            title: this.humanizeFacet(facetName, keywords),
            options: keywords.map(keyword => {
              if (facetName === 'geoid') {
                return {
                  ...keyword,
                  display: this.geoToLabel(keyword, this.$data.geoNames),
                }
              }

              if (facetName.startsWith('report_field_')) {
                return {
                  ...keyword,
                  display: keyword.display,
                  option_value: keyword.value,
                }
              }

              return keyword
            }),
          }))
      }, 300),
      getFacetValue,
      handleLegendProperty(value) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, { value: value })
      },
      handleAddKeyword(item) {
        // The keyword actor_suggestion is not a filter, but an actor suggestion, handle this clicked keyword accordingly
        if (item.facet === 'actor_suggestion') {
          this.handleClickActorSearchResult(item.value)
        } else if (item.facet.includes('report_field_')) {
          var context = { reportId: item.facet, value: item.value, type: 'checkbox' } // 'checkbox' is a default value, we only get checkbox,multi_select and options type keywords back, the handling of these are the same
          this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, context)
        } else {
          this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, item)
        }
      },
      handleClickActorSearchResult(actorId) {
        trackHeapEvent('directoryFilters.search.clickSearchedActor', {
          id: actorId,
          medium: 'desktop',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'scores',
          metaData: actorId,
        })
      },
      handleRemoveKeyword(keyword) {
        // The keyword contains the facet name of the keyword that is removed
        // For simple cases like taxonomy values or tags this is a one to one mapping (tags, membership, industries, ...)
        // For nested keywords, we need to clear the corresponding filter value
        handleRemoveNestedKeyword(this.$store, keyword)
        handleRemoveReportKeyword(this.$store, keyword)

        // TODO: our filters consist of simple key=value pairs, combined with more complex structures (date ranges, report filters)
        // Removing them in 1 operation would be more clean, than the way it is in this function
        // for now, just keep the elementary operations in the filters state file and use those operations separately here
        this.$store.commit(FILTERS_MUTATION_TYPES.REMOVE_KEYWORD, keyword)
      },
      setByFacet(facet, values) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet, values })
      },
      clearBounds() {
        this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_MAP_BOUNDS, {
          tl: null,
          br: null,
        })
      },
      clearAllFilters() {
        this.industrySelection = []
        this.technologySelection = []
        this.activitiesSelection = []
        this.reportFieldsSelection = []
        this.stagesSelection = []
        this.categorySelection = []
        this.sustainabilityGoalSelection = []
        this.domainSelection = []
        this.membershipSelection = []
        /* this.defaultSelection.category = true
        this.defaultSelection.membership = true
        this.defaultSelection.stages = true */
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      },
      showMoreFilterTagsModal() {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MORE_FILTER_TAGS)
      },
      showAllFilters() {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ALL_FILTERS)
      },
    },
    mounted() {
      this.$bus.on('resize', () => {
        if (document.querySelectorAll('.top-filters__filter-containers').length > 0) {
          this.numberOfVisibleFilters = Math.round(document.getElementsByClassName('top-filters__filters-section')[0].clientWidth / document.getElementsByClassName('top-filters__filter-containers')[0].clientWidth)
        }
      })
    },
    mixins: [KeywordLabelMixin, FiltersMixin, ConfigMixin],
    components: {
      CategorizedSearchInput,
      FiltersDropdown,
      HierarchyDropdown,
      Keyword,
      KeywordSuggestionSearchBar,
      Slider,
      SliderFoundingDate,
      SliderFundingDate,
    },
  })
</script>
