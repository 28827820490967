<template>
  <div>
    <h2 class="h2">Homepage</h2>

    <h3 class="h3">Homepage Template & Visuals</h3>

    <div class="row homepage-settings_intro-text">
      <div class="col-md-6 col-xs-12">
        <form-group label="Homepage template">
          <div v-for="(template, templateId) in HOMEPAGE_TEMPLATES">
            <label class="radio"
                   v-if="template !== HOMEPAGE_TEMPLATES['EXPLORATION_FOCUSED'] || showExplorationTemplate">
              <input
                type="radio"
                name="homepage-template"
                :value="templateId"
                :checked="isHomepageTemplateChecked(templateId)"
                @change="handleChangeHomepageTemplate"
              >
              <span class="radio__label">{{ template.displayName }}</span>
            </label>
          </div>
        </form-group>

        <div
          v-if="['knowledge-base', 'directory'].includes(selectedHomepage) && hasHideableSections(form.homepageTemplate)">
          <form-group label="Visible homepage sections">
            <template v-for="section in form.homepageTemplate">
              <div v-if="section.canBeHidden">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :value="section.id"
                    :checked="section.isVisible"
                    v-model="section.isVisible"
                  >
                  <span class="checkbox__label">{{ getHomepageTemplateSectionDisplayName(section.id) }}</span>
                </label>
              </div>
            </template>
          </form-group>

          <form-group label="Select the actors that will be put in the spotlight section of the homepage"
                      :errors="errors.homepageCarrouselPortfolio">
            <dropdown v-model="form.homepageCarrouselPortfolio" :options="portfolios" :allowClear="true"/>
          </form-group>
        </div>

        <!-- DO NOT USE V-IF BECAUSE OF DOM INSERTION ERRORS -->
        <form-group label="Select the topics to display on top of the homepage."
                    :style="{display: isHomepageTemplateChecked('CONTENT_FOCUSED') ? 'block' : 'none'}">
          <dropdown v-model="form.homepage_knowledge_base_tags" multiple :options="knowledgeBaseOptions"/>
        </form-group>
      </div>

      <div class="col-md-6 col-xs-12">
        <form-group label="Introduction Video (Youtube or Vimeo)">
          <ds-input placeholder="Insert iframe embed code or embed url" v-model="form.embedded_video_url"/>
        </form-group>
        <form-group style="width: 300px"
                    label="Introduction image, supports jpeg and png. This image will be used if the introduction video is not configured. If the simplified homepage is used, a good size is 1920x400 pixels.">
          <div id="homepageKnowledgeBaseImageMessage"></div>
          <image-input v-model="form.homepage_knowledge_base_image" width="300" height="150"
                       id="homepageKnowledgeBaseImage"/>
        </form-group>
      </div>
    </div>

    <div v-if="$store.getters.hasAccessToNewSpottingAreas">
      <h2 class="h2">Simplified navigation</h2>

      <form-group
        label="Visible navigation items (only configurable when spotting areas are enabled)">
        <input
          v-model="form.showActorTabInTopBarNavigationSimplified"
          type="checkbox"
        />
        <span class="checkbox__label">Show actor overview</span>
      </form-group>
    </div>

    <h3 class="h3">Introduction text</h3>

    <editor-language-dropdown v-model="editingLocale" style="margin-bottom: 20px; margin-top: 10px;"/>

    <div class="row homepage-settings_intro-text">
      <form-group class="col-md-6 col-xs-12" label="Introduction Title (Logged out)" style="max-width: 100%">
        <ds-input v-model="introTitleLoggedOut" style="max-width: 20em; margin-bottom: 15px;"/>
        <rich-text-area v-model="taglineLoggedOut" :minimal="true"
                        class="announcement-detail-body-style"></rich-text-area>
      </form-group>

      <form-group class="col-md-6 col-xs-12" label="Introduction Title (Logged in)" style="max-width: 100%">
        <ds-input v-model="introTitleLoggedIn" style="max-width: 20em; margin-bottom: 15px;"/>
        <rich-text-area v-model="tagline" :minimal="true" class="announcement-detail-body-style"></rich-text-area>
      </form-group>
    </div>

    <div>
      <form-group label="Headline">
        <dropdown :options="ctaDropdownOptions" v-model="form.headlineType" :allow-clear="true"></dropdown>
      </form-group>
      <form-group
        v-if="form.headlineType && (['global-search', 'challenge'].includes(form.headlineType) || form.headlineType.startsWith('report-template-'))"
        label="Headline Text">
        <ds-input v-model="headlineText"
                  :placeholder="$t('headline_default_message')"></ds-input>
      </form-group>
    </div>
    <div class="row homepage-settings_intro-text">
      <form-group label="Info page" class="col-md-6 col-xs-12">
        <rich-text-area v-model="description" :minimal="true" class="announcement-detail-body-style"></rich-text-area>
      </form-group>
    </div>

  </div>
</template>

<script>
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import { SECTIONS as HOMEPAGE_SECTIONS, TEMPLATES as HOMEPAGE_TEMPLATES } from '../../../constants/homepage.js'
  import { fetchAllPortfolios } from '../../../api/portfolios'

  import UiMixin from '../../../util/UiMixin'
  import ConfigMixin from '../../../util/ConfigMixin'
  import { localizedEditProperty } from '../../../util/TranslationsMixin'
  import RichTextArea from '../../../components/TextArea/RichTextArea.vue'
  import { DEFAULT_LOCALE } from '../../../store/modules/localization'
  import EditorLanguageDropdown from '../../Translations/EditorLanguageDropdown.vue'

  export default {
    name: 'Homepage',
    props: ['form'],
    data () {
      return {
        HOMEPAGE_TEMPLATES,
        HOMEPAGE_SECTIONS,
        portfolios: [],
        errors: {},
        editingLocale: DEFAULT_LOCALE,
      }
    },
    computed: {
      showExplorationTemplate () {
        return this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring
      },
      knowledgeBaseOptions () {
        let fieldListOptions = []

        fieldListOptions = fieldListOptions.concat(
          this.activityLabels,
          this.categoryLabels,
          this.industryLabels,
          this.membershipLabels,
          this.technologyLabels,
          this.domainLabels,
        ).filter(r => r)

        // We sort the array alphabetically
        fieldListOptions.sort()

        return fieldListOptions
      },
      selectedHomepage () {
        return this.form.homepageTemplate && this.form.homepageTemplate[0].id
      },
      introTitleLoggedOut: localizedEditProperty('editingLocale', 'form', 'introTitleLoggedOut'),
      taglineLoggedOut: localizedEditProperty('editingLocale', 'form', 'taglineLoggedOut'),
      introTitleLoggedIn: localizedEditProperty('editingLocale', 'form', 'introTitleLoggedIn'),
      headlineText: localizedEditProperty('editingLocale', 'form', 'headlineText'),
      tagline: localizedEditProperty('editingLocale', 'form', 'tagline'),
      description: localizedEditProperty('editingLocale', 'form', 'description'),
      showChallengeCTA () {
        return this.$store.state.config.challengesAreEnabled && this.canCreateChallenges
      },
      canCreateChallenges () {
        return this.$store.getters.canCreateChallenge
      },
      ctaDropdownOptions () {
        const options = [{ label: 'Global search', value: 'global-search' }]
        if (this.showChallengeCTA) {
          options.push({ label: 'Write a challenge', value: 'challenge' })
        }

        if (this.$store.getters.reportSettings.length) {
          const reportTemplatesWithCallEnabled = this.$store.getters.reportSettings.filter(setting => setting.is_call === true)
          for (const template of reportTemplatesWithCallEnabled) {
            options.push({
              label: `Show countdown for ${template.name || 'Notes'} (until 24 hours after due date)`,
              value: `report-template-${template.id}`,
            })
          }
        }
        return options
      },
    },
    methods: {
      hasHideableSections (homepage) {
        return homepage.find(section => section.canBeHidden)
      },
      handleChangeHomepageTemplate (e) {
        this.form.homepageTemplate = HOMEPAGE_TEMPLATES[e.target.value].sections.map(section => ({
          ...section,
          isVisible: true,
        }))
      },
      isHomepageTemplateChecked (templateId) {
        const template = HOMEPAGE_TEMPLATES[templateId]

        return template.sections.every(templateSection => this.form.homepageTemplate.find(section => section.id === templateSection.id))
      },
      getHomepageTemplateSectionDisplayName (sectionId) {
        const section = Object.values(HOMEPAGE_SECTIONS).find(section => section.id === sectionId)
        return section ? section.displayName : sectionId
      },
      fetchPortfolios () {
        fetchAllPortfolios({ limit: 100, type: 'all' }).then(portfolios => {
          this.portfolios = portfolios.map(portfolio => {
            return { value: portfolio.id, label: portfolio.name }
          })
        }).catch(errors => {
          console.log(errors)
        })
      },
    },
    mounted () {
      this.fetchPortfolios()
    },
    mixins: [
      UiMixin,
      ConfigMixin,
    ],
    components: {
      EditorLanguageDropdown,
      DsTextarea,
      Dropdown,
      ImageInput,
      RichTextArea,
    },
  }
</script>

<style scoped>
  .homepage-settings_intro-text {
    max-width: 100% !important;
    margin-top: 15px;
    margin-bottom: 0;
  }

  #homepageKnowledgeBaseImageMessage {
    color: red;
    padding: 7px 0;
  }
</style>
