import { parseLinkHeader } from '@web3-storage/parse-link-header'
import Raven from 'raven-js'

import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../store/modules/user-helpers.js'

import { inert } from '../util/helpers.js'
import querify from '../util/querify.js'

function checkStatus (response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export default function ({ url, method = 'GET', body }) {
  const options = {
    method: method,
    body: body,
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'x-csrf-token': window.csrf,
    },
  }
  return fetch(url, options)
    .then(resendIf452(url, options))
    .then(checkStatus)
    .then(response => {
      const contentType = response.headers.get('Content-Type')

      if (contentType.includes('application/json')) {
        return response.json()
      } else {
        return response.text()
      }
    })
    .catch(err => {
      console.warn('Request failed:', url, err)
      const contentType = err.response.headers.get('Content-Type')
      if (contentType && contentType.includes('application/json')) {
        return err.response.json()
      }
    })
}

/*
Usage:

createJSONRequest({ url, data })
  .then(data => successful())
  .catch(data => showErrorToUser(data))

.catch(data)
{
  // General feedback
  message: 'General error feedback message',

  // Each field can have feedback
  email: ['problem1', 'problem2'],
  anotherfield: ['problem with this field'],
}
*/
export function createJSONRequest ({ url, method = 'POST', data }) {
  const options = {
    method: method,
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-csrf-token': window.csrf,
    },
  }
  return fetch(url, options)
    .then(resendIf452(url, options))
    .then(checkStatus)
    .then(response => {
      const contentType = response.headers.get('Content-Type')

      if (contentType && contentType.includes('application/json')) {
        return response.json()
      } else {
        return response.text()
      }
    })
    .catch(err => {
      if (import.meta.env.APP_ENV === 'local') {
        console.error(`Error for ${url}`)
        console.error(err)
      }

      if (!err) {
        return
      }

      return new Promise((resolve, reject) => {
        if (unauthorized(err.response)) {
          Raven.setExtraContext({
            url,
            method,
          })
          Raven.captureMessage('Got 401 and showed login modal')

          setLoggedOut()

          return reject({
            message: 'Request unauthorized. You are logged out',
          })
        }
        const contentType = err.response.headers.get('Content-Type')
        if (contentType.includes('application/json')) {
          console.warn('Handling error from', url)

          return err.response.json()
            .then(data => reject(Object.assign({}, data, { statusCode: err.response.status })))
            .catch(data => reject({ message: 'Fatal error occurred' }))
        } else if (err.response.status === 413) {
          /* err.response.text().then(output => {
            Raven.setExtraContext({
              input: data,
              output: output,
              status: err.response.status,
              errors: err
            });
            Raven.captureMessage('An uploaded file/image was too large, method was: ' + method + ' ' + url);
          }); */

          reject({ message: 'The image is too large' })
        } else {
          console.error('No error handling after request failed:', url, err)

          err.response.text().then(output => {
            Raven.setExtraContext({
              input: data,
              output: output,
              status: err.response.status,
              errors: err,
            })
            Raven.captureMessage('Expected JSON from ' + method + ' ' + url)
          })

          reject({ message: 'Unexpected error' })
        }
      })
    })
}

export function createHTMLRequest ({ url }) {
  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'text/html',
      'x-csrf-token': window.csrf,
    },
  })
    .then(checkStatus)
    .then(response => {
      return response.text()
    })
    .catch(err => {
      console.warn('Request failed:', url, err)
      const contentType = err.response.headers.get('Content-Type')
      if (contentType && contentType.includes('application/json')) {
        return err.response.json()
      }
    })
}

export function createFileRequest ({ body, method, url }) {
  return fetch(url, {
    body,
    method: method || 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'x-csrf-token': window.csrf,
    },
  })
    .then(checkStatus)
    .then(response => {
      return response.json()
    })
    .catch(err => {
      return new Promise((resolve, reject) => {
        if (err.response.status === 413) {
          reject({ message: 'The file is too large' })
        }
        return err.response.json()
          .then(errors => reject(errors))
          .catch(errors => reject({ errors, unexpected: true }))
      })
    })
}

/* Returns object
{
  data: [...],
  paging: {
    next: { offset },
    last: { offset }
  }
}
*/
export function createPagedRequest ({ url, method = 'GET' }) {
  const options = {
    method: method,
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'x-csrf-token': window.csrf,
    },
  }
  return fetch(url, options)
    .then(resendIf452(url, options))
    .then(checkStatus)
    .then(response => {
      const total = response.headers.get('x-total')
      const link = response.headers.get('Link')
      const paging = link ? parseLinkHeader(link) : {}
      return new Promise((resolve, reject) => {
        return response.json()
          .then(data => resolve({ data, paging, total }))
          .catch(reject)
      })
    })
    .catch(err => {
      return new Promise((resolve, reject) => {
        if (unauthorized(err.response)) {
          setLoggedOut()

          return reject({
            message: 'You are logged out',
          })
        }
        return err.response.json()
          .catch(() => ({ message: 'Fatal error occurred' }))
          .then(data => {
            console.warn('Handling error from', url, data)
            Raven.setExtraContext({
              input: url,
              output: data,
              status: err.response.status,
              errors: err,
            })
            Raven.captureMessage('Unexpected error in createPagedRequest')
            reject(data)
          })
      })
    })
}

// This function will assign ascending numbers to each request
// The number of the last request is saved in `received`
// When a response is received from a request with a number lower than `received`, it will get rejected
export function cancelIfLate (request) {
  let sent = 0
  let received = 0

  return a => {
    const req = sent += 1
    return request(a)
      .then(data => {
        received !== req - 1 && console.log(received, req)
        if (received < req) {
          received = req
          return data
        }

        // Request Time-out
        return Promise.reject({ code: 408 })
      })
  }
}

export function createResource (url) {
  return {
    get: (path, filters) => createJSONRequest({ url: url + (path ? '/' + path : '') + (filters ? '?' + querify(filters) : ''), method: 'GET' }),
    find: id => createJSONRequest({ url: url + '/' + id, method: 'GET' }),
    post: data => createJSONRequest({ url: data.id ? url + '/' + data.id : url, data, method: data.id ? 'PUT' : 'POST' }),
    delete: id => createJSONRequest({ url: url + '/' + id, method: 'DELETE' }),
    do: (action, data) => createJSONRequest({
      url: url + (data.id ? '/' + data.id : '') + '/' + action,
      data,
      method: 'POST',
    }),
  }
}

let count452 = 0

function resendIf452 (url, options) {
  return response => {
    if (response.status !== 452) {
      return response
    }

    count452++

    if (count452 > 1) {
      window.location.reload()
    }

    // CSRF mismatch, lets try again
    console.warn('CSRF mismatch!')
    return response.json()
      .then(data => {
        console.warn('CSRF token is being refreshed')
        window.csrf = data.token
        options = inert(options)
        options.headers['x-csrf-token'] = data.token
        return fetch(url, options)
      })
  }
}

function unauthorized (response) {
  return response.status === 401
}

let count401 = 0

function setLoggedOut (response) {
  count401++
  console.warn('Setting logged out!')
  // Only check user profile on first 401
  if (count401 !== 1) {
    return
  }

  // Removed during vue3-upgrade since __vue__.$notify doesn't exist anymore, and it didn't really seem to be functional
  // document.querySelector('.app-wrapper').__vue__.$notify({
  //   horizontalAlign: 'right',
  //   verticalAlign: 'bottom',
  //   message: 'Access denied!'
  // })

  /*if (typeof(this) === 'undefined') {
    console.error('Could not trigger user mutation type: logout success')

    return
  }*/

  createJSONRequest({ method: 'GET', url: '/user/current' })
    .catch(profile => profile)
    .then(profile => {
      if (!profile || !profile.id) {
        this.$store.commit(USER_MUTATION_TYPES.LOGOUT_SUCCESS)
      } else {
        // Allow to check again in 5 seconds
        setTimeout(() => {
          count401 = 0
        }, 5000)
      }
    })
}
