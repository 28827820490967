<template>
  <div class="cnz scrollable">
    <SimpleHero :is-foodleap="true" height="70vh"/>
    <Container>
      <div class="cnz__info">
        <div class="cnz__cards">
          <div v-for="data in cardData" class="col-xs-12 col-sm-10 col-md-6 col-lg-4 cnz__card"
               style="color: white;">
            <h2>{{ data.title }}</h2>
            <div class="line"></div>
            <p class="cnz__cards__description">{{ data.description }}</p>
            <div class="cnz__cards__actors">
              <h4>{{ data.subtitle }}</h4>
              <div class="cnz__card_content" v-if="data.key === 'latest_actors'">
                <div v-for="item in latestActors" class="cnz__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="cnz__card_content" v-else-if="data.key === 'latest_products'">
                <div v-for="item in latestProducts" class="cnz__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="cnz__card_content" v-else-if="data.key === 'latest_announcements'">
                <div v-for="item in allActors" class="cnz__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="static-home-foodleap__card_button" @click="goToViewMore(data)">
                <p style="margin: 0;">{{ data.buttonText }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>

    <div class="cnz__info--click-explore">
      <Container>
        <div style="padding-top: 3rem; padding-bottom: 3rem;">
          <h1 class="mb-05 dm-sans-font">Click & Explore in just a few clicks</h1>
          <p>Canterbury Health Innovation is a thriving community of professionals in Health and Health Innovation.
            Explore the ecosystem to build connections and capabilities to
            be
            inspired and grow.</p>
        </div>
      </Container>
    </div>

    <Container style="margin-top: 3rem; margin-bottom: 3rem;">
      <router-link to="/" class="spotting-areas-title">
        <div>
          <!--          {{ $t('ecosystem_title_numbers', { ecosystem: ecosystemDisplayName }) }}-->
          Canterbury Health Innovation Ecosystem in Numbers
        </div>
      </router-link>
      <facet-card
          :source="'home'"
          :facet-counts="facetCounts"
          @click="facetCardClick"
      />
    </Container>

    <div v-if="!fetchingEvents" class="cnz__upcoming-event">
      <Container>
        <div class="cnz_events">
          <div v-if="events.length === 0">
            <div class="cnz_last_event">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 cnz_card_info">
                <h1>{{ $t('home_foodvalley_event_title') }}</h1>
                <h4>There are no upcoming events right now.</h4>
                <ds-button
                    variant="secondary"
                    :label="$t('home_foodvalley_see_all_events')"
                    :size="null"
                    :style="registerButtonStyle"
                    @click="goToEvents"
                />
              </div>
            </div>
          </div>
          <div v-for="item in events" class="cnz_last_event reverse">
            <div class="col-12 col-sm-12 col-md-7 col-lg-7 cnz_card_info">
              <h1>{{ $t('home_foodvalley_event_title') }}</h1>
              <h1>{{ item.title }}</h1>
              <div class="cnz__date">
                <icon name="calendar-empty" size="22"/>
                <p v-html="getFormatEventDate(item, true)"></p>
              </div>
              <div class="cnz_description">
                <p>{{ item.shortDescription }}</p>
                <a :href="'/events/' + (item.slug || item.id)"
                   target="_blank">{{ $t('home_foodvalley_description_button') }}</a>
              </div>
              <ds-button
                  variant="secondary"
                  :label="$t('home_foodvalley_see_all_events')"
                  :size="null"
                  :style="registerButtonStyle"
                  @click="goToEvents"
              />
            </div>
            <div v-if="item.image" class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`/notifications/${item.id}/image`})`}"
            >
              <!--              <img :src="`/notifications/${item.id}/image`" alt="Event Image"/>-->
            </div>
            <div v-else class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`${getDefaultFallbackImageUrl}`})`}"
            >
            </div>
          </div>
        </div>
      </Container>
    </div>

    <div v-if="!fetchingAnnouncements" class="cnz__upcoming-event reverse">
      <Container>
        <div class="cnz_events">
          <div v-if="announcements.length === 0">
            <div class="cnz_last_event">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 cnz_card_info">
                <h1>{{ $t('home_foodvalley_event_title') }}</h1>
                <h4>There are no announcements right now.</h4>
                <ds-button
                    variant="secondary"
                    :label="$t('home_foodvalley_see_all_events')"
                    :size="null"
                    :style="registerButtonStyle"
                    @click="goToEvents"
                />
              </div>
            </div>
          </div>
          <div v-for="item in announcements" class="cnz_last_event">
            <div class="col-12 col-sm-12 col-md-7 col-lg-7 cnz_card_info">
              <h1>NEW ANNOUNCEMENT</h1>
              <h1>{{ item.title }}</h1>
              <div class="cnz_description">
                <p>{{ item.shortDescription }}</p>
                <a :href="'/events/' + (item.slug || item.id)"
                   target="_blank">{{ $t('home_foodvalley_description_button') }}</a>
              </div>
              <ds-button
                  variant="secondary"
                  label="See All Announcements"
                  :size="null"
                  :style="registerButtonStyle"
                  @click="goToEvents"
              />
            </div>
            <div v-if="item.image" class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`/notifications/${item.id}/image`})`}"
            >
            </div>
            <div v-else class="col-12 col-sm-12 col-md-5 col-lg-5 cnz_image"
                 :style="{backgroundImage: `url(${`${getDefaultFallbackImageUrl}`})`}"
            >
            </div>
          </div>
        </div>
      </Container>
    </div>

    <Container>
      <div v-if="resources.length" class="cnz__communities">
        <h1>Interesting resources</h1>
        <p>Explore a selection of insightful and valuable resources.</p>

        <Carousel
            :key="'carousel-communities-resources'"
            :items-to-show="2"
            :mouse-drag="false"
            :settings="carouselSettings(2)"
            :breakpoints="carouselBreakpoints(2)"
        >
          <Slide
              v-for="(resource, index) in resources"
              :key="'resource-communities-' + index"
          >
            <div @click="goToKnowledgeBase"
                 style="width: 100%; height: calc(100% - 20px); padding: 20px 10px 20px 10px; cursor: pointer;">
              <div class="resource--card">
                <h3 style="text-align: left">{{ resource.title }}</h3>
                <p style="text-align: left; font-size: 13px;">{{ resource.body.substring(0, 500) }}</p>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation v-if="resources.length > 2"/>
          </template>
        </Carousel>
      </div>
    </Container>

    <Container>
      <div class="spotting-areas-highlighted__section">
        <div class="spotting-areas-highlighted__background"></div>
        <div class="spotting-areas-highlighted__section__container">
          <div class="spotting-areas-highlighted__section" style="width: 48%">
            <div class="spotting-areas-title">
              {{ linkToMapData.title }}
            </div>
            <div class="spotting-areas-highlighted">
              <div class="highlighted_spotting-area__content">
                <p v-html="linkToMapData.description"></p>
                <ds-button
                    class="highlight-spotting-area-button"
                    :style="{backgroundColor: '#000000'}"
                    :href="linkToMapData.url"
                    :label="linkToMapData.buttonText"
                ></ds-button>
              </div>
            </div>
          </div>
          <div
              v-if="(linkToMapData.image || getDefaultFallbackImageUrl())"
              :style="{ backgroundImage: `url(${linkToMapData.image || getDefaultFallbackImageUrl()})` }"
              class="highlighted_spotting-area__content image"
              style="width: 50%; height: 350px; background-repeat: no-repeat; background-position: center; align-self: center;margin-left: 30px;"
              :class="{ 'full-size': true, 'fallback-image': !linkToMapData.image}"
          >
            <div
                v-if="(linkToMapData.image || getDefaultFallbackImageUrl())"
                :style="{ backgroundImage: `url(${linkToMapData.image || getDefaultFallbackImageUrl()})`}"
                class="simplified-connection__logo"
                :class="{ 'full-size': true, 'fallback-image': !linkToMapData.image}"
            />
          </div>
        </div>
      </div>
    </Container>

    <SimplifiedFooter :with-margin="false"/>
  </div>
</template>

<script lang="ts">
import SimpleHero from '../SimpleHero/SimpleHero.vue'
import Avatar from '../Avatar/Avatar.vue'
import { fetchPublicNotifications } from '../../api/notifications.js'
import DsButton from '../DsButton/DsButton.vue'
import SimplifiedCommunityCard from '../Simplified/SimplifiedCommunityCard.vue'
import { getDefaultFallbackImageUrl, getImageForCommunity } from '../../util/helpers.js'
import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
import { fetchStatsData } from '../../api/homepage.js'
import Container from '../Container/Container.vue'
import { getFormatEventDate } from '../../util/date.js'
import moment from 'moment'
import { defineComponent } from "vue";
import { trackMatomoEvent } from '@/util/analytics'
import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'
import FacetCard from '@/pages/Simplified/FacetCard.vue'
import { getTranslationsForTaxonomyValue } from '@/store/modules/taxonomies'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '@/store/modules/filters'
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '@/store/modules/actors'
import AnnouncementOrResourceCard from '@/components/Simplified/AnnouncementOrResourceCard.vue'
import { fetchKnowledgeBaseData, likeArticle } from '@/api/knowledge-base'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import CarouselMixin from '@/util/CarouselMixin'
import { fetchActors } from '@/api/actors'
import SimplifiedImageCard from '@/components/Connection/SimplifiedImageCard.vue'
import SimplifiedImageCardWithCta from '@/components/SpottingAreas/SimplifiedImageCardWithCta.vue'
import AnnouncementCard from '@/components/Simplified/AnnouncementCard.vue'
import CommunityCard from '@/components/Simplified/CommunityCard.vue'

export default defineComponent({
  mixins: [CarouselMixin],
  data: () => {
    return {
      events: [],
      announcements: [],
      fetchingEvents: false,
      fetchingAnnouncements: false,
      latestActors: [],
      latestProducts: [],
      allActors: [],
      facetCounts: [],
      resources: [],
      cardData: [
        {
          key: 'latest_actors',
          title: 'Health Innovators',
          description: 'Find interesting high quality contacts. with extensive experience and working in innovation ecosystems worldwide.',
          subtitle: 'Latest added',
          buttonHref: '/actors-simplified',
          buttonText: 'view more',
        },
        {
          key: 'latest_products',
          title: 'Incubators & Accelerators',
          description: 'Search for innovation facilities to speed up time to market or share your spare capacity with others and connect with interesting partners.',
          subtitle: 'Latest added',
          buttonHref: '/actors-simplified',
          buttonText: 'view more',
        },
        {
          key: 'latest_announcements',
          title: 'Partners at CNZ',
          description: 'Interact with interesting partners to ask questions and share your ideas and discuss earlier findings.',
          subtitle: 'Latest added',
          buttonHref: '/actors-simplified',
          buttonText: 'view more',
        },
      ],

    }
  },
  computed: {
    ecosystemDisplayName(): string {
      return this.$store.getters.ecosystemDisplayName
    },
    linkToMapData() {
      if (this.$store.getters.isEcHealth) {
        return {
          title: "MedTechIQ",
          description: "The MedTechIQ AeroSpaceNZ Map is a detailed visualization of key locations and organizations driving innovation in New Zealand's aerospace and medical technology sectors. Designed to provide clear insights into the regional landscape, this map serves as a valuable reference for professionals, researchers, and stakeholders seeking to navigate and connect with the industry's most impactful hubs.",
          url: "https://static.datascouts.eu/medtech-iq-cc-focus.html",
          buttonText: "Visit Map",
          image: "/images/static-home-page/ec-health/medtech-iq.png",
        }
      } else if (this.$store.getters.isEcAerospace) {

      } else if (this.$store.getters.isEcCleantech) {

      } else if (this.$store.getters.isAgritechHub) {

      } else {
        return {
          title: "MedTechIQ",
          description: "The MedTechIQ AeroSpaceNZ Map is a detailed visualization of key locations and organizations driving innovation in New Zealand's aerospace and medical technology sectors. Designed to provide clear insights into the regional landscape, this map serves as a valuable reference for professionals, researchers, and stakeholders seeking to navigate and connect with the industry's most impactful hubs.",
          url: "https://static.datascouts.eu/medtech-iq-cc-focus.html",
          buttonText: "Visit Map",
          image: "/images/static-home-page/ec-health/medtech-iq.png",
        }
      }
    },
    getFirstColumnActorPortfolio() {
      if (this.$store.getters.isEcHealth) {
        return "b3koWA8kWr"
      } else if (this.$store.getters.isEcAerospace) {
        return ""
      } else if (this.$store.getters.isEcCleantech) {
        return ""
      } else if (this.$store.getters.isAgritechHub) {
        return ""
      } else {
        return "wVdp1MPpaE"
      }
    },
    getSecondCardActorPortfolio() {
      if (this.$store.getters.isEcHealth) {
        return "L0V4v3Lkb7"
      } else if (this.$store.getters.isEcAerospace) {
        return ""
      } else if (this.$store.getters.isEcCleantech) {
        return ""
      } else if (this.$store.getters.isAgritechHub) {
        return ""
      } else {
        return "wVdp1MPpaE"
      }
    },
    getThirdColumnActorPortfolio() {
      if (this.$store.getters.isEcHealth) {
        return "aAVbz5OVmr"
      } else if (this.$store.getters.isEcAerospace) {
        return ""
      } else if (this.$store.getters.isEcCleantech) {
        return ""
      } else if (this.$store.getters.isAgritechHub) {
        return ""
      } else {
        return "wVdp1MPpaE"
      }
    },
    highlightedSpottingArea() {
      return this.$store.state.spottingAreas.highlightedSpottingArea.data
    },
    highlightedSpottingAreaImage() {
      return getDefaultFallbackImageUrl()
    },
    registerButtonStyle() {
      return {
        backgroundColor: '#2b4557',
        color: 'white',
        fontFamily: '',
        fontWeight: 'bold',
        height: 'auto',
        width: '',
        textTransform: 'capitalize',
        borderRadius: '4px',
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: '50px',
        fontSize: '16px',
      }
    },
    hasAccessToCommunities() {
      return this.$store.getters.hasAccessToCommunities
    },
    communities() {
      // return this.$store.state.communities.listData.data.slice(0, 3)
      return this.$store.state.communities.listData.data
    },
  },
  methods: {
    goToKnowledgeBase() {
      this.$router.push('/dashboards/knowledge-base')
    },
    likeArticle({ resourceId, status }) {
      likeArticle(resourceId, status)
    },
    fetchLatestNews() {
      return fetchKnowledgeBaseData({ limit: 10, offset: 0, relevantOnly: true, tags: 871 })
          .then(news => {
            var items = news
                .data.results
                .map(response => {
                  if (['rss', 'pr', 'blog'].includes(response.media_type) && response.sql_media_id) {
                    response.detail_link = '/files/' + response.sql_media_id
                  }
                  return response
                })

            this.resources = items
          })
          .catch(error => {
            console.log(error)
          })
    },
    facetCardClick(facet) {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
        facet: facet.facetType,
        value: facet.value,
      })

      const listFilters = { ...this.$store.getters.listFilterObject }

      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

      if (this.isMember) {
        this.$router.push('/actors')
      } else {
        this.$router.push('/actors-simplified')
      }
    },
    fetchFacetCounts() {
      const parameters = {}

      fetchStatsData(parameters)
          .then(response => {
            const resultOrdered = response.data.stats.map(result => {
              const translations = getTranslationsForTaxonomyValue(response.data.property, result.value)
              let label = ''

              if (translations && translations[this.locale] && translations[this.locale].name) {
                label = translations[this.locale].name
              } else {
                label = translations['en'].name
              }

              result['label'] = label
              result['count'] = result.total
              result['facetName'] = response.data.property

              return result
            })

            // Only return the top 6
            this.facetCounts = resultOrdered
                .sort((a, b) => b.count - a.count)
                .slice(0, 6)
          })
          .catch(errors => {
            //
          })
    },
    goToHighlightedSpottingAreaDetail() {
      trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.HOMEPAGE_BUTTON, this.highlightedSpottingArea.id)
      this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
    },
    formatEventDate(date, format = 'ddd, Do MMM YYYY, hh:mmA') {
      date = moment(date).format() // Date() has issues cross browser if it's not a standard ISO format: https://stackoverflow.com/questions/39969570/deprecation-warning-in-moment-js-not-in-a-recognized-iso-format
      return moment(new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Brussels' })).format(format)
    },
    getFormatEventDate,
    getImageForCommunity,
    getDefaultFallbackImageUrl,
    goToActorDetailPage(actor) {
      this.$router.push(`/actors/${actor.id}`)
    },
    goToAnnouncementDetailPage(announcement) {
      this.$router.push(`/announcements/${announcement.slug || announcement.id}`)
    },
    goToViewMore(data) {
      this.$router.push(data.buttonHref)
    },
    goToEvents() {
      this.$router.push('/events-simplified')
    },
    goToAnnouncements() {
      this.$router.push('/announcements-simplified')
    },
    fetchEvents() {
      this.fetchingEvents = true

      return fetchPublicNotifications(1, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.events = response
            this.fetchingEvents = false
          })
          .catch(error => {
            this.fetchingEvents = false

            console.log(error)
          })
    },
    fetchAnnouncements() {
      this.fetchingAnnouncements = true

      return fetchPublicNotifications(1, 0, { is_event: false })
          .then(response => {
            this.announcements = response
            this.fetchingAnnouncements = false
          })
          .catch(error => {
            this.fetchingAnnouncements = false

            console.log(error)
          })
    },
    fetchLatestActors() {
      const actorsParameters = { limit: 3, portfolio: this.getFirstColumnActorPortfolio } // b3koWA8kWr

      fetchActors(actorsParameters)
          .then((actors) => {
            this.latestActors = actors.data
          })
    },
    fetchLatestProducts() {
      const actorsParameters = { limit: 3, portfolio: this.getSecondCardActorPortfolio } // L0V4v3Lkb7

      fetchActors(actorsParameters)
          .then((actors) => {
            this.latestProducts = actors.data
          })
    },
    fetchAllActors() {
      const actorsParameters = { limit: 3, portfolio: this.getThirdColumnActorPortfolio } // aAVbz5OVmr

      fetchActors(actorsParameters)
          .then((actors) => {
            this.allActors = actors.data
          })
    },
  },
  async mounted() {
    this.fetchLatestActors()
    this.fetchLatestProducts()
    this.fetchAllActors()
    this.fetchEvents()
    this.fetchFacetCounts()
    this.fetchAnnouncements()
    this.fetchLatestNews()
  },
  components: {
    CommunityCard,
    AnnouncementCard,
    SimplifiedImageCardWithCta,
    SimplifiedImageCard,
    AnnouncementOrResourceCard,
    FacetCard,
    SimplifiedCommunityCard,
    SimpleHero,
    Avatar,
    DsButton,
    SimplifiedFooter,
    Container,
    Carousel,
    Slide,
    Navigation
  },
})
</script>


<style lang="scss" scoped>
@import "resources/assets/scss/variables";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.mb-05 {
  margin-bottom: .5rem;
}

.mt-4rem {
  margin-top: 4rem;
}

.cnz__info--click-explore {
  background-color: #231F20;
  padding-bottom: 1rem;
  color: white;
}

.cnz.scrollable {
  h1 {
    font-size: 48px;
    font-family: "DM Sans", sans-serif;
  }

  h5 {
    font-size: 16px;
    font-family: "DM Sans", sans-serif;
  }

  p {
    font-size: 18px;
    font-family: "Work Sans", sans-serif;
  }

  .mb-05 {
    margin-bottom: .5rem;
  }
}

.cnz__info {
  margin-bottom: 4em;

  .cnz__cards {
    display: flex;
    justify-content: center;
    margin-top: -100px;
    gap: 2rem;

    .cnz__cards__description {
      min-height: 150px;
      max-height: 150px;
    }

    .cnz__card {
      width: 400px;
      max-width: 340px;
      min-height: 500px;
      background-color: white;
      height: 100%;
      border-radius: 8px;
      padding: 30px 20px 20px 20px;
      position: relative;
      color: #231F20;
      box-shadow: 0 0 20px 0 rgba(27, 50, 67, .15);

      .line {
        width: 25px;
        height: 4px;
        margin-top: 0.3rem;
        background-color: #ED1C24;
      }

      .cnz__card_content {
        margin-top: 20px;

        .avatar {
          background-color: rgba(33, 36, 74, .20) !important;
        }
      }

      h2 {
        color: black;
      }

      h4 {
        color: black;
      }

      .cnz__card_item {
        display: flex;
        margin-bottom: 25px;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        p {
          margin: 0 10px;
          color: black;
          font-size: 14px;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .avatar {
          width: 35px;
          height: 35px;
          background-color: rgba(255, 255, 255, .20) !important;

          :deep(.avatar__img) {
            width: 35px;
            height: 35px;
          }
        }
      }

      p {
        margin: 20px 0 20px 0;
        color: black;
      }

      .static-home-foodleap__card_button {
        cursor: pointer;

        p {
          color: #ED1C24;
          text-decoration: underline;
          font-size: 14px;
        }
      }
    }
  }
}

.cnz__upcoming-event.reverse {
  background-color: #231f20;

  h1:first-child {
    text-transform: uppercase;
    color: white !important;
    font-weight: 400;
    font-size: 24px;
  }

  h1 {
    color: white;
  }

  p {
    color: white;
  }
}

.cnz__upcoming-event {
  background-color: #c8d8d2;
  margin-bottom: 3rem;

  .cnz_events {
    padding: 3em 1em;

    .cnz_last_event.reverse {
      flex-direction: row-reverse;

      .cnz_card_info {
        h1 {
          color: black;
        }

        p {
          color: black;
        }

        a {
          color: black;
        }
      }

      h1:first-child {
        text-transform: uppercase;
        color: black !important;
        font-weight: 400;
        font-size: 24px;
      }

      h1 {
        color: black;
      }

      p {
        color: black;
      }
    }

    .cnz_last_event {
      display: flex;

      .cnz_card_info {
        h1:first-child {
          text-transform: uppercase;
          color: #707070;
          font-weight: 400;
          font-size: 24px;
        }

        h1 {
          color: white;
        }

        p {
          color: white;
        }

        a {
          color: white;
        }

        h1:nth-child(2) {
          margin-top: 10px;
        }

        .cnz__date {
          display: flex;
          align-items: center;
          margin-top: 10px;

          p {
            margin-left: 10px;
            font-size: 14px;
          }
        }

        .cnz_description {
          color: #707070;
          margin-top: 20px;

          p {
            white-space: pre-line;
            width: 97%;
            font-size: 14px;
          }
        }
      }

      .cnz_image {
        margin-top: 20px;
        width: 430px;
        height: 430px;
        border: 8px solid white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

.cnz__communities {
  margin-top: 5rem;
  margin-bottom: 5rem;

  .resource--card {
    box-shadow: 0 0 20px 0 rgba(27, 50, 67, .05);
    padding: 1rem;
    min-height: 240px;
    max-height: 240px;

    &:hover {
      box-shadow: 0 0 20px 0 rgba(27, 50, 67, .15);
    }
  }

  h1 {
    color: black;
    text-align: center;
  }

  p {
    text-align: center;
    margin-top: 20px;
    color: #707070;
  }

  .cnz__communities_list {
    width: 340px;
  }

  .cnz__communities_list_view {
    overflow-y: auto;
    padding: 20px;
    display: flex;
    margin-top: 3rem;
    gap: 2rem;
  }
}

.cnz {
  @media(max-width: $screen-xl) {
    .cnz__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 2rem;
    }

    .cnz__communities_list_view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 2rem;
    }
  }

  @media(max-width: $screen-lg) {
    .cnz__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: -100px;
      align-items: center;

      .cnz__card {
        width: 100% !important;
        margin: 20px;
        max-width: 100% !important;
      }
    }

    .cnz_events {
      display: flex;
      flex-direction: column;
      padding: 3em 2em;

      .cnz_last_event {
        display: flex;
        flex-direction: column;

        .cnz_card_info .cnz_description p {
          width: 100%;
        }
      }

      .cnz_image {
        margin-top: 20px;
      }

      .static-home-foodleap_information {
        p {
          width: 100%;

        }
      }
    }

    .cnz__communities_list {
      width: 300px;
    }

    .cnz__communities_list_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }

  }
}

@media(max-width: $screen-md-max) {
  .cnz__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: normal !important;

    .cnz__card {
      width: 100% !important;
      margin: 20px;
    }
  }

  .cnz_events {
    display: flex;
    flex-direction: column;
    padding: 3em 2em;

    .cnz_last_event {
      display: flex;
      flex-direction: column;

      .cnz_card_info .cnz_description p {
        width: 100%;
      }
    }

    .cnz_image {
      margin-top: 20px;
    }

    .static-home-foodleap_information {
      p {
        width: 100%;

      }
    }
  }

  .cnz__communities_list {
    width: 200px;
  }

  .cnz__communities_list_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
}

.spotting-areas-highlighted__section__container {
  display: flex;
  flex-direction: row;

  padding-top: 50px;
  padding-bottom: 50px;
}

.spotting-areas-highlighted__section {
  position: relative;

  .spotting-areas-highlighted__background {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    width: 100vw;
    height: 100%;
    background-color: #e9e9e9;
    z-index: -1;
  }

  .spotting-areas-highlighted {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding-bottom: 50px;

    .highlight-spotting-area-button {
      margin-left: 0;
      padding: 10px 15px 10px 15px !important;
    }

    :deep(ul) {
      list-style-type: disc !important;
      margin-left: 15px;
    }

    .highlighted_spotting-area__content {

      &.image {
        padding-top: 50px;
      }

      h1 {
        margin-bottom: 12px;
      }

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 12;
        position: relative;
        text-overflow: ellipsis;


        max-height: 300px;
        list-style-type: disc;
      }
    }
  }
}
</style>
