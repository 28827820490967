<template>
  <div class="fixed-heading">
    <div class="curation fixed-heading scrollable">
      <div v-if="billingData" class="has-pagination">
        <table class="table table--fill table--hover">
          <thead>
          <tr class="row-curation">
            <td>Package</td>
            <td>User</td>
            <td>Date</td>
            <td>Status</td>
          </tr>
          </thead>
          <tbody>
          <tr class="row-curation" v-for="bill in billingData">
            <td class="text-left">{{ humanize(bill.description) }}</td>
            <td>{{ bill.user.name }}</td>
            <td>{{ new Date(bill.created_at).toLocaleString() }}</td>
            <td>
              <p v-if="bill.status !== 'paid'" class="status" :class="'status--' + bill.status">{{ humanize(bill.status) }}</p>

              <p v-else @click="downloadInvoice(bill.id)" style="cursor: pointer;" class="status" :class="'status--' + bill.status">{{ humanize(bill.status) }}
                <icon v-if="bill.status === 'paid'" name="download" size="12"/>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import NewCard from '../../components/NewCard/NewCard.vue'
  import { defineComponent } from 'vue'
  import { fetchBillingPayments } from '@/api/billing'
  import { humanize } from '@/constants/properties'

  export default defineComponent({
    data () {
      return {
        billingData: null,
      }
    },
    computed: {},
    methods: {
      humanize,
      downloadInvoice (id) {
        window.open('/api/billing/invoice/' + id, '_blank');
      },
      fetchPayments () {
        fetchBillingPayments()
          .then((data) => {
            this.billingData = data
          })
      },
    },
    created () {
      this.fetchPayments()
    },
    components: {
      NewCard,
    },
  })
</script>

<style lang="scss">
  @import '../../../scss/variables';

  .status {
    /* Use a lighter version of the color prop for the background. Replace "--color" with the variable you use for colors. */
    background-color: var(--color-light, #f0f0f0); /* Default to a light gray if no color is defined */
    padding: 0.25rem;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    width: 80px;
  }

  .status--paid {
    color: green;
    background-color: #dfffd6; /* Light green for open status */

    &:hover {
      background-color: green;
      color: #dfffd6;
    }
  }

  .status--open {
    color: #4180fd;
    background-color: #d6ebff; /* Blue green for open status */
  }

  .status--in_progress {
    color: orange;
    background-color: #fff4d6; /* Light orange for in-progress status */
  }

  .status--canceled {
    color: red;
    background-color: #ffd6d6; /* Light red for canceled status */
  }
</style>
