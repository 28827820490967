<template>
  <div class="basic-simplified-hero" :style="basicSimplifiedHeroStyling">
    <BasicHero
      :style="basicHeroStyling"
      :title="title"
      :subtitle="subtitle"
      :header-style="headerStyle"
      :hero-content-style="heroContentStyle"
      :title-style="titleStyle"
      :sub-title-style="subTitleStyle"
      :show-logo="showLogo"
      :logo-style="logoStyle"
    >
      <slot></slot>
    </BasicHero>

  </div>
</template>

<script>
  import BasicHero from '../BasicHero/BasicHero.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'basic-simplified-hero',
    props: {
      title: String,
      subtitle: String,
      displayHeroImage: {
        type: Boolean,
        default: false,
      },
      imageHeroHeight: {
        type: Number,
        default: 300,
      },
      showLogo: {
        type: Boolean,
        default: false,
      },
      logoStyle: {
        type: Object,
        default: null,
      },
      headerStyle: {
        type: Object,
        default: null,
        required: false,
      },
      heroContentStyle: {
        type: Object,
        default: null,
        required: false,
      },
      titleStyle: {
        type: Object,
        default: null,
        required: false,
      },
      subTitleStyle: {
        type: Object,
        default: null,
        required: false,
      },
    },
    components: {
      BasicHero
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      image () {
        if (this.$store.getters.isStaging) {
          return '/images/static-home-page/static-home-page-foodleap.png'
        }

        return this.config.homepage_knowledge_base_image
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      displayImageAsHeroBackground () {
        return this.isCommunityEcosystem || (this.image && this.displayHeroImage)
      },
      basicSimplifiedHeroStyling () {
        if (this.displayImageAsHeroBackground) {
          return `background-image: url(${this.image});
        min-height: ${this.imageHeroHeight}px;
        display: flex;
        ${this.headerStyle ? '' : 'justify-content: center;'}
        background-size: cover;
        background-repeat: no-repeat;`;
        }

        return `background-color: var(--primary)`
      },
      basicHeroStyling () {
        if (this.displayImageAsHeroBackground) {
          if (this.headerStyle) {
            return this.headerStyle
          }

          return `background: transparent; color: #323441; display: flex; flex-direction: column; justify-content: center; padding-top: 0; align-items: center;'`
        }
      }
    },
  })
</script>

<style lang="scss" scoped>
  .basic-simplified-hero {
    width: 100%;
    margin-bottom: 50px;

    .basic-simplified-hero__title {
      letter-spacing: 2px;
      color: white;
      font-size: 28px;
      text-transform: uppercase;
      margin-left: auto;
      margin-right: auto;
    }

    .basic-simplified-hero__subtitle {
      color: white;
      font-size: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }


</style>
