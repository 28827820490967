<template>
  <div class="ec-health scrollable">
    <BasicSimplifiedHero
      :title="title"
      :subtitle="tagline"
      :display-hero-image="true"
      :image-hero-height="550"
      :header-style="headerStyle"
      :hero-content-style="heroContentStyle"
      :title-style="heroTitleStyle"
      :sub-title-style="heroSubTitleStyle"
      :show-logo="true"
      :logo-style="logoStyle"
    />

    <template
      v-if="announcements.length > 0"
      :key="'news-feed-carousel'"
    >

      <div style="background-color: #c8d8d2; margin-top: -50px;">
        <Container>
          <div class="ec-health_events" style="padding: 1em;">
            <div
              class="communities-announcements row"
              :style="announcementsSpacingStyle"
            >
              <div
                class="vertical-challenge-container col-xs-6 col-sm-6 col-md-4"
                v-for="(announcement, index) in announcementsToDisplay"
                :key="'announcement' + index"
              >
                <simplified-community-card
                  style="margin-top: -10rem;"
                  background-color="white"
                  :title="announcement.title"
                  :image="getImageForAnnouncement(announcement)"
                  :description="announcement.body"
                  :href="'/announcements/' + announcement.id"
                  button-text="Find out more"
                  variant="ec-health"
                  full-image
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </template>

    <div>
      <div style="width: 80%; padding-top: 2rem; margin: 0 auto;">
        <h1 class="ec-health__title" style="margin-bottom: 2rem;">Find Others in the Ecosystem Directory</h1>
        <iframe
          v-if="$store.getters.isEcHealth"
          src="https://static.datascouts.eu/medtech-iq-iframe-health.html"
          width="100%"
          height="800"
          frameborder="0"
        ></iframe>
        <iframe
          v-else-if="$store.getters.isEcAerospace"
          src="https://static.datascouts.eu/cnz-iframe-aerospace.html"
          width="100%"
          height="800"
          frameborder="0"
        ></iframe>
        <iframe
          v-else-if="$store.getters.isAgritechHub"
          src="https://static.datascouts.eu/agritech-iframe-agritech-hub.html"
          width="100%"
          height="800"
          frameborder="0"
        ></iframe>
        <iframe
          v-else-if="$store.getters.isEcCleantech"
          src="https://static.datascouts.eu/cleantech-iframe-aerospace.html"
          width="100%"
          height="800"
          frameborder="0"
        ></iframe>
      </div>
    </div>

    <div v-if="!fetchingEvents" class="ec-health__upcoming-event">
      <Container>
        <div class="ec-health_events" style="padding: 1em;">
          <div v-if="events.length === 0">
            <div class="ec-health_last_event">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 ec-health_card_info">
                <h1 style="color: #c72c3a;">New Event</h1>
                <h4>There are no upcoming events right now.</h4>
                <ds-button
                  variant="secondary"
                  label="See all events"
                  :size="null"
                  :style="registerButtonStyle"
                  @click="goToEvents"
                />
              </div>
            </div>
          </div>

          <h1 class="ec-health__title" v-if="events.length > 0">New Event</h1>

          <div v-for="item in events.slice(0,1)" v-if="events.length > 0" class="ec-health_last_event" style="margin-left: -1em;">
            <div class="col-12 col-sm-12 col-md-7 col-lg-7 ec-health_card_info">
              <h1>{{ item.title }}</h1>
              <div class="ec-health__date">
                <icon name="calendar-empty" size="22"/>
                <p v-html="getFormatEventDate(item, true)"></p>
              </div>
              <div class="ec-health_description">
                <p>{{ item.shortDescription }}</p>
                <a
                  :href="'/events/' + (item.slug || item.id)"
                  target="_blank"
                >Read more</a>
              </div>
              <ds-button
                variant="secondary"
                label="See all events"
                :size="null"
                :style="registerButtonStyle"
                @click="goToEvents"
              />
            </div>
            <div v-if="item.image" class="col-12 col-sm-12 col-md-5 col-lg-5 ec-health_image">
              <img :src="`/notifications/${item.id}/image`" alt="Event Image"/>
            </div>
            <div v-else class="col-12 col-sm-12 col-md-5 col-lg-5 ec-health_image">
              <img :src="getDefaultFallbackImageUrl()" alt="Event Image"/>
            </div>
          </div>
        </div>
      </Container>
    </div>

    <SimplifiedFooter :with-margin="true"/>
  </div>
</template>

<script lang="ts">
  import SimpleHero from '../SimpleHero/SimpleHero.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import { fetchPublicNotifications } from '../../api/notifications.js'
  import DsButton from '../DsButton/DsButton.vue'
  import { getDefaultFallbackImageUrl, getImageForAnnouncement, getImageForCommunity, isIE11 } from '../../util/helpers.js'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
  import { fetchLatestMarkedAsRelevantNews } from '../../api/homepage.js'
  import Container from '../Container/Container.vue'
  import { getFormatEventDate } from '../../util/date.js'
  import moment from 'moment'
  import { defineComponent } from "vue";
  import { actorLogo } from "@/util/actor";
  import ActorMiniCard from '@/components/ActorMiniCard/ActorMiniCard.vue'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  import TaxonomyNavigator from '@/components/TaxoNavigator/TaxonomyNavigator.vue'
  import SimplifiedImageCard from '@/components/Connection/SimplifiedImageCard.vue'
  import AnnouncementOrResourceCard from '@/components/Simplified/AnnouncementOrResourceCard.vue'
  import NewCard from '@/components/NewCard/NewCard.vue'
  import CarouselMixin from '@/util/CarouselMixin'
  import CategoryCount from '@/components/CategoryCount/CategoryCount.vue'
  import MapView from '@/components/MapView/MapView.vue'
  import NonIdealState from '@/components/NonIdealState/NonIdealState.vue'
  import HomePageDirectorySearch from '@/components/HomePageDirectorySearch/HomePageDirectorySearch.vue'
  import ArticlePreview from '@/components/ArticlePreview/ArticlePreview.vue'
  import SimplifiedAnnouncementPreview from '@/components/Simplified/SimplifiedAnnouncementPreview.vue'
  import BasicHero from '@/components/BasicHero/BasicHero.vue'
  import TranslationsMixin from '@/util/TranslationsMixin'
  import BasicSimplifiedHero from '@/components/Simplified/BasicSimplifiedHero.vue'
  import SimplifiedCommunityCard from '@/components/Simplified/SimplifiedCommunityCard.vue'
  import UiMixin from '@/util/UiMixin'

  export default defineComponent({
    data() {
      return {
        visibleTaxonomies: [
          'categories',
          'activities',
          'technologies',
          'industries',
          'memberships',
          'domains'
        ],
        events: [],
        fetchingEvents: false,
        latestActors: [],
        latestNews: [],
        announcements: [],
      }
    },
    mixins: [CarouselMixin, TranslationsMixin, UiMixin],
    computed: {
      announcementsSpacingStyle() {
        return {
          display: 'flex',
          justifyContent: this.announcementsToDisplay.length <= 2 ? 'center' : 'space-between',
        };
      },
      announcementsToDisplay() {
        if (this.isMobile) {
          return this.announcements.slice(0, 2)
        }

        return this.announcements.slice(0, 3)
      },
      isMobile() {
        return this.ui.isMobile
      },
      logoStyle() {
        return {
          width: "100%",
          display: "flex",
          marginTop: "-1rem",
          marginBottom: "2rem",
          marginLeft: "0.5rem",
          minHeight: "50px",
          height: "50px",
          maxHeight: "100px",
          maxWidth: "280px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url('${this.logo}')`,
        }
      },
      logo() {
        return this.config.logo_url_light || this.config.logo_url
      },
      config() {
        return this.$store.state.config
      },
      heroTitleStyle() {
        const width = '4ch'; // Adjust for first 4 characters or set a fixed width
        return {
          fontSize: "2rem",
          fontWeight: "500",
          textTransform: "uppercase",
          textDecoration: "underline",
          textDecorationColor: "#ed1c24",
          textDecorationThickness: "2px",
          textUnderlinePosition: "under", // For more control over position
          textUnderlineOffset: "3px", // Adjust vertical position of the underline
        };
      },
      heroSubTitleStyle() {
        return {
          fontSize: "1.4rem",
          fontWeight: "100",
          marginTop: "1rem"
        }
      },
      heroContentStyle() {
        return {
          color: "white !important",
          textAlign: "left",
          maxWidth: "800px",
          marginLeft: "1rem",
        }
      },
      headerStyle() {
        return {
          alignItems: "flex-start",
          backgroundColor: 'rgba(0, 0, 0, 0.4) !important',
          width: "100%",
        }
      },
      tagline() {
        return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'tagline')
          : this.localizedDisplayProperty(this.config, 'taglineLoggedOut')
      },
      headlineText() {
        return this.localizedDisplayProperty(this.config, 'headlineText')
      },
      title() {
        return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'introTitleLoggedIn')
          : this.localizedDisplayProperty(this.config, 'introTitleLoggedOut')
      },
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      registerButtonStyle() {
        return {
          backgroundColor: '#21244A',
          color: 'white',
          fontFamily: '',
          fontWeight: 'bold',
          height: 'auto',
          width: '',
          textTransform: 'capitalize',
          borderRadius: '4px',
          alignSelf: 'center',
          textAlign: 'center',
          marginTop: '50px',
          fontSize: '16px',
        }
      },
      hasAccessToCommunities() {
        return this.$store.getters.hasAccessToCommunities
      },
      communities() {
        return this.$store.state.communities.listData.data
      },
    },
    methods: {
      isIE11,
      getImageForAnnouncement,
      getFormatEventDate,
      getImageForCommunity,
      getDefaultFallbackImageUrl,
      actorLogo,
      fetchLatestMarkedAsRelevantNews() {
        fetchLatestMarkedAsRelevantNews()
          .then(response => {
            this.latestNews = response
          })
          .catch(error => {
            console.log(error)
          })
      },
      getFeaturedImageStyle(actor) {
        return `background-image: url(${actorLogo(actor)}); background-size: contain; width: 100px; min-height: 100px`
      },
      formatEventDate(date, format = 'ddd, Do MMM YYYY, hh:mmA') {
        date = moment(date).format() // Date() has issues cross browser if it's not a standard ISO format: https://stackoverflow.com/questions/39969570/deprecation-warning-in-moment-js-not-in-a-recognized-iso-format
        return moment(new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Brussels' })).format(format)
      },
      goToEvents() {
        this.$router.push('/events-simplified')
      },
      fetchEvents() {
        this.fetchingEvents = true

        fetchPublicNotifications(1, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.events = response
            this.fetchingEvents = false
          })
          .catch(error => {
            this.fetchingEvents = false

            console.log(error)
          })
      },
      fetchLatestAnnouncements() {
        fetchPublicNotifications(3, 0, { is_event: false })
          .then(items => {
            this.announcements = items || []
          })
      }
    },
    mounted() {
      this.fetchLatestMarkedAsRelevantNews()
      this.fetchEvents()
      this.fetchLatestAnnouncements()
    },
    components: {
      BasicSimplifiedHero,
      BasicHero,
      SimplifiedAnnouncementPreview,
      ArticlePreview,
      HomePageDirectorySearch, NonIdealState, MapView, CategoryCount,
      NewCard, AnnouncementOrResourceCard,
      SimplifiedImageCard,
      TaxonomyNavigator,
      Navigation,
      ActorMiniCard,
      SimplifiedCommunityCard,
      SimpleHero,
      Avatar,
      DsButton,
      SimplifiedFooter,
      Container,
      Carousel,
      Slide,
    },
  })
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .ec-health__title {
    /*color: #c43a3d;*/
    color: #ed1c24;
  }

  .healthtech-superstars {
    background-color: #f9f9f9;
    padding: 40px 0;

    h1 {
      margin-bottom: 1rem;
    }
  }

  .ec-health__info {
    font-family: Museo, serif;

    p {
      font-family: 'Zen Kaku Gothic New', sans-serif;
    }

    .ec-health__cards {
      display: flex;
      justify-content: center;
      gap: 4rem;

      .ec-health__card {
        width: 400px;
        margin-top: -45px;
        max-width: 340px;
        background-color: white;
        filter: drop-shadow(0 3px 6px rgba(#000, 0.16));
        height: 100%;
        border-radius: 8px;
        padding: 30px 20px 20px 20px;
        position: relative;

        .ec-health__card_content {
          margin-top: 20px;

          .avatar {
            background-color: rgba(33, 36, 74, .20) !important;
          }
        }

        h2 {
          font-weight: 500;
          color: var(--primary);
        }

        h4 {
          color: var(--primary);
        }

        .ec-health__card_item {
          display: flex;
          margin-bottom: 25px;
          align-items: center;

          &:hover {
            cursor: pointer;
          }

          p {
            margin: 0 10px;
            color: var(--primary);
            font-size: 14px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .avatar {
            width: 35px;
            height: 35px;
            background-color: rgba(33, 36, 74, .20) !important;

            :deep(.avatar__img) {
              width: 35px;
              height: 35px;
            }
          }
        }

        p {
          margin: 20px 0 20px 0;
          color: #788088;
        }

        .static-home-foodleap__card_button {
          text-decoration: underline;
          cursor: pointer;

          p {
            color: var(--primary);
          }
        }
      }
    }
  }

  /* c8d8d2  e9d9ca c43a3d */
  .ec-health__upcoming-event {
    background-color: #c8d8d2;

    .ec-health_events {
      padding: 1em 1em;

      .ec-health_last_event {
        display: flex;
        margin-top: 1rem;

        .ec-health_card_info {
          h1:first-child {
            text-transform: uppercase;
            color: #707070;
            font-weight: 400;
            font-size: 24px;
          }

          h1:nth-child(2) {
            margin-top: 10px;
          }

          .ec-health__date {
            display: flex;
            align-items: center;
            margin-top: 10px;

            p {
              margin-left: 10px;
              font-size: 14px;
            }
          }

          .ec-health_description {
            color: #707070;
            margin-top: 20px;

            p {
              white-space: pre-line;
              width: 97%;
              font-size: 14px;
            }
          }
        }

        .ec-health_image {
          margin-top: 20px;

          img {
            border: 8px solid white;
          }
        }
      }
    }
  }

  .ec-health__communities {
    margin-top: 5rem;

    h1 {
      color: #21244A;
      text-align: center;
    }

    p {
      text-align: center;
      margin-top: 20px;
      color: #707070;
    }

    .ec-health__communities_list {
      width: 340px;
    }

    .ec-health__communities_list_view {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      gap: 2rem;
    }
  }

  .ec-health {
    font-family: "Open Sans", sans-serif;

    @media(max-width: $screen-xl) {
      .ec-health__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }

      .ec-health__communities_list_view {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
      }
    }

    @media(max-width: $screen-lg) {
      .ec-health__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: -100px;
        align-items: center;

        .ec-health__card {
          width: 100% !important;
          margin: 20px;
          max-width: 100% !important;
        }
      }

      .ec-health_events {
        display: flex;
        flex-direction: column;
        padding: 3em 2em;

        .ec-health_last_event {
          display: flex;
          flex-direction: column;

          .ec-health_card_info .ec-health_description p {
            width: 100%;
          }
        }

        .ec-health_image {
          margin-top: 20px;
        }

        .static-home-foodleap_information {
          p {
            width: 100%;

          }
        }
      }

      .ec-health__communities_list {
        width: 300px;
      }

      .ec-health__communities_list_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
      }

    }
  }

  @media(max-width: $screen-md-max) {
    .ec-health__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      align-items: normal !important;

      .ec-health__card {
        width: 100% !important;
        margin: 20px;
      }
    }

    .ec-health_events {
      display: flex;
      flex-direction: column;
      padding: 3em 2em;

      .ec-health_last_event {
        display: flex;
        flex-direction: column;

        .ec-health_card_info .ec-health_description p {
          width: 100%;
        }
      }

      .ec-health_image {
        margin-top: 20px;
      }

      .static-home-foodleap_information {
        p {
          width: 100%;

        }
      }
    }

    .ec-health__communities_list {
      width: 200px;
    }

    .ec-health__communities_list_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }
  }

  .spotting-areas-highlighted__section__container {
    display: flex;
    flex-direction: row;

    padding-top: 50px;
    padding-bottom: 50px;
  }

  .spotting-areas-highlighted__section {
    position: relative;

    .spotting-areas-highlighted__background {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      width: 100vw;
      height: 100%;
      background-color: var(--primary-extra-lightest); /* Vervang dit door jouw gewenste kleurcode */
      z-index: -1;
    }

    .spotting-areas-highlighted {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: var(--primary-extra-lightest);
      position: relative;
      padding-bottom: 50px;

      .highlight-spotting-area-button {
        margin-left: 0;
      }

      :deep(ul) {
        list-style-type: disc !important;
        margin-left: 15px;
      }

      .highlighted_spotting-area__content {

        &.image {
          padding-top: 50px;
        }

        h1 {
          margin-bottom: 12px;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 12;
          position: relative;
          text-overflow: ellipsis;


          max-height: 300px;
          list-style-type: disc;
        }
      }
    }
  }
</style>
