<template>
  <template v-if="isCommunityEcosystem">
    <SimplifiedFooterCommunityEcosystem :with-margin="withMargin"/>
  </template>

  <div v-else class="simplified-footer" :class="{'withMargin': withMargin}">
    <div class="row">
      <div class="col-xs-12">
        <div class="simplified-footer__content">
          <div class="simplified-footer__top">
            <div class="simplified-footer__top__left" :style="{width: topSectionWidth}">
              <a :href="externalUrl" class="logo__container">
                <div
                  v-if="logo"
                  :style="`background-image: url('${logo}');`"
                  class="simplified-footer__logo_image"
                />
              </a>
              <a
                href="/info" target="_blank" rel="noopener noreferrer"
                @click="trackHeapEvent('simplifiedFooter.information')">{{
                  $t('link_information')
                }}</a>
              <template v-if="externalUrlLabel !== ''">
                <a
                  :href="externalUrl" target="_blank"
                  rel="noopener noreferrer">{{ externalUrlLabel }}</a>
              </template>
              <template v-for="(link, index) in homeLinks">
                <br v-if="localizedLinkText(index) && !link.isMultiline"><a
                :href="localizedLinkUrl(index)"
                v-if="localizedLinkText(index) && !link.isMultiline"
                :class="localizedLinkUrl(index) ? '' : 'no-link'" target="_blank"
                rel="noopener noreferrer">{{ localizedLinkText(index) }}</a>
              </template>
              <a :href="externalUrl" v-if="config.footer_logo" class="logo__container">
                <div
                  :style="`background-image: url('${config.footer_logo}');`"
                  class="simplified-footer__logo_image"
                />
              </a>
            </div>
            <div
              class="simplified-footer__top__middle__left"
              v-if="config.footerDescription" :style="{width: topSectionWidth}">
              <div class="simplified-footer__description" v-if="config.footerDescription">
                {{ config.footerDescription }}
              </div>
            </div>
            <div
              class="simplified-footer__top__middle__right" v-if="emailAddress"
              :style="{width: topSectionWidth}">
              <div
                class="simplified-footer__contact" v-if="emailAddress"
                :class="(config.footerCtaEnabled || facebookUrl || twitterUrl || linkedInUrl || instagramUrl) ? '' : 'align-right'">
                <icon name="email-outline" size="22"/>
                <br>
                {{ $t('simplified_footer_contact') }}
                <br>
                <!--                <template v-if="userLoggedIn">-->
                <!--                  <a href="#" @click="openContactModal()" rel="noopener noreferrer"
                                     style="font-weight: 500;"
                                     v-if="emailAddress">{{ $t('link_contact') }}</a>-->
                <!--                </template>-->
                <!--                <template v-else>-->
                <a
                  :href="`mailto:${emailAddress}`" target="_blank"
                  rel="noopener noreferrer" v-if="emailAddress"
                  @click="trackHeapEvent('simplifiedFooter.contactEmail')">{{
                    $t('link_contact')
                  }}</a>
                <!--                </template>-->
              </div>
            </div>
            <div
              class="simplified-footer__top__right" v-if="config.footerCtaEnabled || facebookUrl || twitterUrl || linkedInUrl || instagramUrl"
              :style="{width: topSectionWidth}">
              <template v-if="config.footerCtaEnabled">
                <template v-if="!config.footerCtaUrl">
                  <div
                    v-for="(line, index) in config.footerCtaText.split('<br>')"
                    :key="'ctaLine' + index">{{ line }}
                  </div>
                </template>
                <div v-else>
                  <ds-button
                    class="simplified-footer__cta_button" variant="default"
                    :href="config.footerCtaUrl"
                    :label="config.footerCtaText"/>
                </div>
              </template>
              <div v-if="facebookUrl || twitterUrl || linkedInUrl || instagramUrl">
                <br v-if="config.footerCtaEnabled">
                <div class="simplified-footer__icons">
                  <a
                    :href="facebookUrl" target="_blank" rel="noopener noreferrer"
                    v-if="facebookUrl">
                    <icon name="facebook-logo" fill="white"/>
                  </a>
                  <a
                    :href="twitterUrl" target="_blank" rel="noopener noreferrer"
                    v-if="twitterUrl">
                    <icon name="twitter-logo"/>
                  </a>
                  <a
                    :href="linkedInUrl" target="_blank" rel="noopener noreferrer"
                    v-if="linkedInUrl">
                    <icon name="linkedin-logo"/>
                  </a>
                  <a
                    :href="instagramUrl" target="_blank" rel="noopener noreferrer"
                    v-if="instagramUrl">
                    <icon name="instagram-logo" style="top: 2px"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="simplified-footer__bottom">
            <div class="simplified-footer__bottom__left" :style="{width: bottomSectionWidth}">
              <i18n-t scope="global" keypath="in_collaboration_with" tag="span">
                <template v-slot:entity>
                  <a
                    href="https://datascouts.eu/" target="_blank"
                    rel="noopener noreferrer">DataScouts</a>
                </template>
              </i18n-t>
            </div>
            <div class="simplified-footer__bottom__middle" :style="{width: bottomSectionWidth}">
              <div style="">
                <a
                  :href="termsOfUseUrl" target="_blank" rel="noopener noreferrer"
                  @click="trackHeapEvent('simplifiedFooter.termsOfUse')">
                  {{ $t('terms_of_use') }}
                </a>&nbsp;-&nbsp;
                <a
                  :href="privacyPolicyUrl" target="_blank" rel="noopener noreferrer"
                  @click="trackHeapEvent('simplifiedFooter.privacyPolicy')">
                  {{ $t('privacy_policy') }}
                </a>&nbsp;-&nbsp;
                <a
                  :href="cookiePolicyUrl" target="_blank" rel="noopener noreferrer"
                  @click="trackHeapEvent('simplifiedFooter.cookiePolicy')">
                  {{ $t('cookie_policy') }}
                </a>
              </div>
            </div>
            <div class="simplified-footer__bottom__right" :style="{width: bottomSectionWidth}">
              {{ config.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import objectPath from 'object-path'
  import Container from '../Container/Container.vue'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import { DEFAULT_LOCALE } from '../../store/modules/localization'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { DEFAULT_URLS } from '../../constants/default-urls.js'
  import UiMixin from '../../util/UiMixin.js'
  import SimplifiedFooterCommunityEcosystem from './SimplifiedFooterCommunityEcosystem.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      withMargin: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      numberOfTopSections () {
        let result = 1

        if (this.emailAddress) {
          result++
        }
        if (this.config.footerDescription) {
          result++
        }
        if (this.config.footerCtaEnabled) {
          result++
        }

        return result
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      isMobile () {
        return this.ui.isMobile
      },
      bottomSectionWidth () {
        if (this.isMobile) {
          return '100%'
        }
        return '33.333%'
      },
      topSectionWidth () {
        if (this.isMobile) {
          return '100%'
        }

        switch (this.numberOfTopSections) {
          case 1:
            return '100%'
          case 2:
            return '50%'
          case 3:
            return '33.333%'
          case 4:
            return '25%'
        }
      },
      logo () {
        return this.config.logo_url_light || this.config.logo_url
      },
      config () {
        return this.$store.state.config
      },
      homeLinks () {
        return (window.config.homeLinks || []).reduce((acc, link) => {
          // [...] we don't support multiline homelinks anymore
          acc.push(link)
          return acc
        }, [])
      },
      userLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      emailAddress () {
        return this.config.email
      },
      facebookUrl () {
        return this.config.facebookUrl
      },
      twitterUrl () {
        return this.config.twitterUrl
      },
      linkedInUrl () {
        return this.config.linkedInUrl
      },
      instagramUrl () {
        return this.config.instagramUrl
      },
      termsOfUseUrl () {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl () {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
      cookiePolicyUrl () {
        return this.config.cookiePolicyUrl || DEFAULT_URLS.COOKIE_POLICY
      },
      externalUrl () {
        return this.config.homepage
      },
      externalUrlLabel () {
        return this.localizedDisplayProperty(this.config, 'externalUrlLabel')
      },
    },
    methods: {
      trackHeapEvent,
      openContactModal () {
        trackHeapEvent('simplifiedFooter.contactModal')
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          sendTo: 'owners',
          placeholder: '',
          title: 'Get in touch',
          subject: 'Get in touch', // The subject that will be set in the email we send to owners
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT_SUPPORT, {})
      },
      localizedLinkText (index) {
        let result = this.homeLinks[index].text

        if (this.locale !== DEFAULT_LOCALE) {
          result = objectPath.get(this.config, `translations.${this.locale}.homeLinksText${index}`, result)
        }

        return result
      },
      localizedLinkUrl (index) {
        let result = this.homeLinks[index].url

        if (this.locale !== DEFAULT_LOCALE) {
          result = objectPath.get(this.config, `translations.${this.locale}.homeLinksUrl${index}`, result)
        }

        return result
      },
    },
    mixins: [
      UiMixin,
      TranslationsMixin,
    ],
    components: {
      Container,
      SimplifiedFooterCommunityEcosystem,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  $spacing: 30px;

  .simplified-footer {
    background: #2A3646;
    font-size: 12px;
  }

  .simplified-footer.withMargin {
    margin-top: auto;
  }

  .simplified-footer__content {
    color: white;
    line-height: 1.5;
    padding: calc(#{$spacing} / 2);

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .simplified-footer__top {
    display: flex;

    .logo__container {
      display: flex;
      justify-content: left;

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 10px;
      }
    }
  }

  .simplified-footer__top__right {
    margin-left: auto;
    text-align: right;
  }

  a.simplified-footer__cta_button {
    color: var(--primary);
    white-space: normal;
    word-break: break-word;

    &:hover {
      color: var(--primary);
      background-color: white;
    }
  }

  .simplified-footer__logo_image {
    min-height: 40px;
    width: 200px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }

  .simplified-footer__bottom__right {
    margin-left: auto;
    text-align: right;
    text-transform: uppercase;
  }

  .simplified-footer__top__left, .simplified-footer__top__right {
    padding: calc($spacing / 2);
  }

  .simplified-footer__bottom__left, .simplified-footer__bottom__middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .simplified-footer__contact {
    @media screen and (min-width: $screen-md) {
      text-align: center;

      &.align-right {
        text-align: right;
      }
    }

    :deep(path) {
      fill: white;
    }
  }

  .simplified-footer__description {
    text-align: justify;
  }

  .simplified-footer__bottom__left {
    padding: calc($spacing / 2);
  }

  .simplified-footer__bottom__right {
    padding: calc($spacing / 2);
  }

  .simplified-footer__bottom__middle {
    padding: calc($spacing / 2);
    flex-grow: 1;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .simplified-footer__icons {
    font-size: 0;
    display: inline-block;
    width: 100px;
    white-space: initial;

    a {
      background: white;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: inline-block;
      padding: 9px 13px;

      .svg-icon--world {
        top: 2px;
      }

      :deep(g) {
        fill: black;
      }
    }
  }

  .simplified-footer__bottom {
    display: flex;
  }

  .simplified-footer__top__left {
    .no-link:hover {
      cursor: initial;
      text-decoration: none;
    }
  }

  .simplified-footer__top__middle__left, .simplified-footer__top__middle__right {
    padding: calc($spacing / 2);
  }

  @media screen and (max-width: $screen-md) {
    .simplified-footer__logo_image {
      background-position: center;
    }

    .simplified-footer__top {
      flex-wrap: wrap;

      .logo__container {
        justify-content: center;
      }
    }

    .simplified-footer__description {
      width: 50%;
      text-align: center;
    }

    .simplified-footer__bottom {
      display: block;
    }

    .simplified-footer__top__left, .simplified-footer__top__right, .simplified-footer__bottom__left, .simplified-footer__bottom__right, .simplified-footer__top__middle__right, .simplified-footer__top__middle__left, .simplified-footer__bottom__middle {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      justify-content: center;
      flex-grow: 1;
    }

    .simplified-footer__description {
      width: 100%;
    }
  }

  .simplified-footer__icons {
    .fill-fg, #Fill-1, path {
      fill: black !important;
    }
  }
</style>
