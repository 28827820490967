import { cancelIfLate, createJSONRequest, default as createRequest } from './create-request'

const teamId = window.config.id

export const logoutRequest = () => createJSONRequest({
  url: '/logout',
  data: {},
}).catch(() => {
  createRequest({
    url: '/logout',
  })
})

export const loginRequest = (data) => createJSONRequest({
  url: '/login',
  data: data,
})

export const registerRequest = (data) => createJSONRequest({
  url: '/register',
  data: data,
})

export const verifyCsrfToken = cancelIfLate(() => createJSONRequest({
  url: '/api/verify-csrf',
  method: 'GET',
}))

export const finaliseRegistrationRequest = (data) => createJSONRequest({
  url: '/finaliseRegistration',
  data: data,
})

export const forgotPasswordRequest = (data) => createJSONRequest({
  url: '/password/email',
  data: data,
})

export const changePasswordRequest = (data) => createJSONRequest({
  url: '/password/reset',
  data: data,
})

export const changeEmailRequest = (newEmail) => createJSONRequest({
  url: '/api/profile/new-email',
  method: 'PUT',
  data: {
    newEmail,
  },
})

export const fetchProfile = data => createJSONRequest({
  url: `/api/profile`,
  method: 'GET',
  data,
})

export const fetchActorProfiles = data => createJSONRequest({
  url: `/api/profile/all`,
  method: 'GET',
  data,
})

export const updateProfile = data => createJSONRequest({
  url: `/api/profile`,
  method: 'PUT',
  data,
})

export const updatePhoneNumber = data => createJSONRequest({
  url: `/api/profile/phone-number`,
  method: 'PUT',
  data,
})

export const updateActiveLocale = locale => createJSONRequest({
  url: '/api/profile/locale',
  method: 'PUT',
  data: { locale },
})

export const updatePersonalisedContentSetting = (settingValue) => createJSONRequest({
  url: '/api/profile/allow-personalised-content',
  method: 'PUT',
  data: {
    allowPersonalisedContent: settingValue,
  },
})

export const leaveEcosystem = (transferDataToUserEmail = '') => createJSONRequest({
  url: `/api/profile/leave-ecosystem`,
  method: 'DELETE',
  data: {
    transferDataToUserEmail,
  },
})

export const getTransferableDataInformation = (userId = '') => createJSONRequest({
  url: `/api/profile/get-transferable-data-count?userId=${userId}`,
  method: 'GET',
})

export const getCandidateUsersToTransferDataTo = (userId = '') => createJSONRequest({
  url: `/api/profile/get-candidates-to-transfer-data-to?userId=${userId}`,
  method: 'GET',
})

export const acceptTerms = () => createJSONRequest({
  url: `/api/profile/accept-terms`,
  method: 'PUT',
  data: {},
})

export const acceptCookies = () => createJSONRequest({
  url: `/api/profile/accept-cookies`,
  method: 'PUT',
  data: {},
})

export const requestAccessRequest = () => createJSONRequest({
  url: `/api/request-access`,
  method: 'POST',
})

export const cancelInvitation = (data) => createJSONRequest({
  url: `/settings/ecosystems/${teamId}/invitations/${data.id}`,
  method: 'DELETE',
})

export const resendInvitation = (data) => createJSONRequest({
  url: `/settings/ecosystems/${teamId}/invitations/${data.id}/resend`,
  method: 'GET',
})

export const resendConfirmation = (data) => createJSONRequest({
  url: `/api/settings/users/${data.id}/resendConfirmation`,
  method: 'GET',
})

/**
 * Logging
 */
export const logActivity = (activity, subjectId = '') => createJSONRequest({
  url: `/api/user-activity?activity=${activity}&subject_id=${subjectId}`,
  method: 'GET',
})

/**
 * Subscriptions & Billing
 */
export const getStripeIntent = () => createJSONRequest({
  url: `/api/profile/stripe-intent`,
  method: 'GET',
})

export const validateCouponCode = (promoCode, packageId) => createJSONRequest({
  url: `/api/profile/validate-promo-code?promoCode=${promoCode}&packageId=${packageId}`,
  method: 'GET',
})

export const getPaymentInfo = () => createJSONRequest({
  url: `/api/profile/payment-information`,
  method: 'GET',
})

export const getSubscription = () => createJSONRequest({
  url: `/api/profile/subscription`,
  method: 'GET',
})

export const updateSubscription = (data) => createJSONRequest({
  url: `/api/profile/subscription`,
  method: 'PUT',
  data: data,
})

export const cancelSubscription = () => createJSONRequest({
  url: `/api/profile/subscription`,
  method: 'DELETE',
})

export const resumeSubscription = () => createJSONRequest({
  url: `/api/profile/subscription/resume`,
  method: 'PUT',
})

export const addPaymentMethod = (data) => createJSONRequest({
  url: `/api/profile/payment-method`,
  method: 'POST',
  data: data,
})

export const updateDefaultPayment = (data) => createJSONRequest({
  url: `/api/profile/payment-method`,
  method: 'PUT',
  data: data,
})

export const deletePaymentMethod = (data) => createJSONRequest({
  url: `/api/profile/payment-method`,
  method: 'DELETE',
  data: data,
})

export const getBillingInfo = () => createJSONRequest({
  url: `/api/profile/billing-info`,
  method: 'GET',
})

export const updateBillingInfo = (data) => createJSONRequest({
  url: `/api/profile/billing-info`,
  method: 'PUT',
  data: data,
})

export const getInvoices = () => createJSONRequest({
  url: `/api/profile/invoices`,
  method: 'GET',
})

export const getLastUploadedFiles = () => createJSONRequest({
  url: `/user/last-uploaded-files`,
  method: 'GET',
})

export const getRelevantTaxonomies = () => createJSONRequest({
  url: `/api/profile/relevant-taxonomies`,
  method: 'GET',
})

export const updateRelevantTaxonomies = (data) => createJSONRequest({
  url: `/api/profile/relevant-taxonomies`,
  method: 'POST',
  data,
})

export const getRelevantTags = () => createJSONRequest({
  url: `/api/profile/relevant-tags`,
  method: 'GET',
})

export const updateRelevantTags = (data) => createJSONRequest({
  url: `/api/profile/relevant-tags`,
  method: 'POST',
  data,
})

export const getCommunities = (userId) => createJSONRequest({
  url: `/api/profile/communities`,
  method: 'POST',
  data: { userId: userId },
})

export const sendInvitationAcceptedEmailToOwners = (userId) => createJSONRequest({
  url: `/api/profile/send-invitation-accepted-email`,
  method: 'POST',
  data: { userId: userId },
})
