<template>
  <div class="spotting-area-hero" :class="{'collapsed': collapseHero}">
    <div class="container">
      <ds-button
        class="second-top-bar__link-item"
        label="Go back to spotting areas"
        icon="arrow-left"
        variant="minimal"
        to="/spotting-areas"
        style="margin-top: 15px; color: white; fill: white;"
        v-tooltip="'Go back to spotting areas'"
        icon-fill-color="#ffffff"
      />
      <h4 class="spotting-area-hero__collapsed-spotting-area-title" v-if="collapseHero">
        {{ spottingArea.name }}
      </h4>

      <BasicHero
        show-expand-button
        :title="spottingArea.name"
        :subtitle="showSubTitle ? spottingArea.description : ''"
        :collapseHero="collapseHero"
      >
        <follow-button
          v-if="showingFollowButton"
          class="a-button" :model-value="isFollowed(spottingArea)"
          @update:modelValue="setFollowed(spottingArea, $event)"
        />

        <slot v-if="$slots.navigation" name="navigation">
        </slot>
      </BasicHero>
    </div>
  </div>
</template>

<script>
  import FollowButton from '../../components/FollowButton/FollowButton.vue'
  import BasicHero from '../BasicHero/BasicHero.vue'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import SpottingAreaMixin from '../../util/SpottingAreaMixin'

  export default {
    props: {
      spottingArea: {},
      showSubTitle: {
        default: true,
        type: Boolean,
      },
      collapseHero: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      followedSpottingAreaIds () {
        return this.$store.state.user.profile.conceptSearches || []
      },
      conceptSearchIsFollowed () {
        const map = {}

        for (const id of this.followedSpottingAreaIds) {
          map[id] = true
        }

        return map
      },
      showingFollowButton () {
        return false
      },
    },
    methods: {
      isFollowed () {
        return this.spottingAreaIsFollowed[this.spottingArea.id]
      },
      setFollowed (followed) {
        // trackHeapEvent('SpottingAreaSimplified.toggleFollowing')
        if (followed) {
          this.followSpottingArea(this.spottingArea.id)
        } else {
          this.unfollowSpottingArea(this.spottingArea.id)
        }
      },
    },
    mixins: [TranslationsMixin, SpottingAreaMixin],
    components: {
      BasicHero,
      FollowButton,
    },
  }
</script>

<style lang="scss">

  .spotting-area-hero__collapsed-spotting-area-title {
    color: white;
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 0;
    margin-left:10px;
  }

  .button--minimal:hover .svg-icon path {
    fill: white !important;
  }

  .spotting-area-hero {
    background: var(--primary);
    z-index: 21;

    &.collapsed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0 6px 5px -4px rgba(0, 0, 0, 0.5);
    }

    .container {
      margin: 0 auto;

      &.basic-hero__subtitle {
        margin: 0;
      }
    }

    .basic-hero {
      padding-top: 2.5rem;
      padding-bottom: 0px;
      text-align: left;

      .basic-hero__slot {
        width: 100%;

        .basic-hero-nav-bar {
          background: transparent;
          justify-content: flex-start;

          .top-bar__links {
            display: flex;
            overflow: hidden;
            justify-content: flex-start;
            align-items: baseline;

            .button {
              padding: 10px 12px 10px;
            }

            :deep(.button__label) {
              color: white;
              text-transform: uppercase;
            }

            .button--minimal:focus {
              // override !important in focus-state.scss
              box-shadow: none !important;
            }


            .second-top-bar__link-active {
              background-color: rgba(204, 204, 204, 0.1);
              color: white;
            }
          }
        }
      }
    }
  }

</style>
