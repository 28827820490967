<template>
  <with-configuration-tabs>
    <div v-if="!form && panel !== 'users' && panel !== 'invitations' && panel !== 'maintenance-requests'">Loading
      config...
    </div>
    <component v-else-if="fullPanel" :is="panel"/>
    <div v-else class="settings fixed-heading" :class="{ scrollable: fullScroll }">
      <div class="heading" style="display: flex; flex-direction: row; justify-content: space-between">
        <div>
          <h1>{{ title }}</h1>
          <p class="guide_text">{{ info }}</p>
        </div>
      </div>
      <!--      <div v-if="panel == 'spotting-areas'" class="spotting-areas-highlighted">
              <form-group
                :label="`Choose the highlighted ${spottingAreaLabel} & color`"
                class="ecosystem-member-communities__general-input"
                info-text="Make sure the spotting area is added to the accessible data, configurable in the permissions per role."
              >
                <div class="spotting-area-list-container"
                     style="display: flex; flex-direction: row;" v-if="availableSpottingAreas.length > 0">
                  <dropdown
                    :new-prepend-icon="'location-marker'"
                    :append-icon="'fas fa-chevron-down'"
                    v-model="selectedSpottingArea"
                    :options="availableSpottingAreas"
                    style="width: 250px;"
                    is-highlighted-spotting-area-dropdown
                  />
                  <color-picker :disabled="selectedSpottingArea == 'none'" :colors="{}" title=""
                                v-model="selectedSpottingAreaColor"
                                @updatePrimary="selectedSpottingAreaColor = $event"
                                :initial-value="selectedSpottingAreaColor"/>
                </div>
              </form-group>
            </div>-->
      <div v-if="panel == 'spotting-areas'" class="communities-announcements-permission">
        <form-group
          :label="`Choose focus on ${spottingAreasLabel}`"
          class="ecosystem-member-communities__general-input"
        >
          <div
            style="display: flex; flex-direction: row;"
          >
            <radio-button
              v-model="form.spottingAreasFocus"
              name="role"
              :label="'Focus on overview'"
              :val="'overview'"
              :required="true"
            />
            <radio-button
              v-model="form.spottingAreasFocus"
              name="role"
              :label="'Focus on actors'"
              :val="'actors'"
              :required="true"
            />
          </div>
        </form-group>
        <!--      <checkbox v-model="toggleSpottingAreaFocusOnActors" :label="`Choose focus on ${spottingAreasLabel}`"/>-->
        <button @click="save(true)" class="button button--secondary">
          {{ labelSaveSpottingAreasFocusOn }}
        </button>
      </div>
      <component :class="{'scrollable' : !fullScroll, 'not-scrollable': fullScroll, 'has-padding': hasPadding}"
                 :is="panel"/>
      <action-bar :editing="isEditing">
        <ds-button variant="secondary" label="Save changes" @click="save"
                   :disabled="form && form.busy || errors.count > 0"/>
        <span class="action-bar__message" v-if="success">{{ savedMessage }}</span>
      </action-bar>
    </div>
  </with-configuration-tabs>
</template>

<script lang="ts">
  import Developer from '../components/Settings/Developer.vue'
  import Ecosystem from '../components/Settings/Ecosystem.vue'
  import Subscription from '../components/Settings/Subscription.vue'
  import Invitations from '../components/Settings/Invitations.vue'
  import Keys from '../components/Settings/CrmIntegrations.vue'
  import Reporting from '../components/Settings/Reporting.vue'
  import EcosystemHeartbeat from '../components/Settings/EcosystemHeartbeat.vue'
  import EcosystemReporting from '../components/Settings/EcosystemReporting.vue'
  import DataConsumption from '../components/Settings/DataConsumption.vue'
  import Publisher from '../components/Settings/Publisher.vue'
  import Users from '../components/Settings/Users.vue'
  import MaintenanceRequests from '../components/Settings/MaintenanceRequests.vue'
  import Communities from '../components/Settings/Communities.vue'
  import SpottingAreas from '../components/Settings/SpottingAreas.vue'
  import Integrations from '../components/Settings/Integrations.vue'
  import Services from '../components/Settings/Services.vue'
  import CustomEmails from '../components/Settings/CustomEmails.vue'
  import Onboarding from '../components/Settings/Onboarding.vue'
  import CustomScores from '../components/Settings/CustomScores.vue'
  import EcosystemMemberPackages from '../components/Settings/EcosystemMemberPackages.vue'
  import Exploration from '../components/Datalab/Exploration.vue'

  import ActionBar from '../components/Form/ActionBar.vue'
  import WithConfigurationTabs from './WithConfigurationTabs/WithConfigurationTabs.vue'

  import { updateConfig, updateMonthlyEnrichment } from '../api/config.js'
  import { updatePortfolioEnrichmentScope, updatePortfolioMonitorScope } from '../api/portfolios.js'
  import { emptyConfig, fetchAndPrepareConfigForEditing, removeDefaultsFrom } from '../util/config.js'
  import { inert } from '../util/helpers.js'
  import ConfigMixin from '../util/ConfigMixin.js'

  import { ACTION_TYPES as LOCALIZATION_ACTION_TYPES } from '../store/modules/localization.js'
  import TranslationsMixin from '../util/TranslationsMixin.js'
  import { defineComponent } from 'vue'
  import RadioButton from "@/components/Form/RadioButton.vue";
  import FormGroup from "@/components/Form/FormGroup.vue";
  import Checkbox from "@/components/Form/Checkbox.vue";
  import Dropdown from "@/components/Dropdown/Dropdown.vue";
  import { ManageSpottingAreas } from "@/api/spottingareas";
  import ColorPicker from "@/components/Form/ColorPicker.vue";
  import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from "@/store/modules/spottingareas";
  import UserMixin from '@/util/UserMixin'
  import BillingOverview from '@/components/Settings/BillingOverview.vue'

  export default defineComponent({
    name: 'settings',
    data() {
      return {
        form: null,
        errors: {},
        success: '',
        fullScroll: false,
        saving: false,
        savingComplete: false,
        spottingAreas: [],
      }
    },
    mixins: [ConfigMixin, TranslationsMixin, UserMixin],
    computed: {
      updatedSpottingAreaHighlight: {
        get() {
          return this.$store.state.spottingAreas.highlightedSpottingArea.editedData
        },
        set(value: number) {
          this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.EDITED_SPOTTING_AREA_HIGHLIGHT, {
            id: value,
            color: this.selectedSpottingAreaColor
          })
        }
      },
      selectedSpottingArea: {
        get() {
          return this.updatedSpottingAreaHighlight.id ?? this.$store.state.spottingAreas.highlightedSpottingArea.data.id ?? 'none'
        },
        set(value: number | string) {
          let color = this.selectedSpottingAreaColor
          if (value == 'none') {
            color = '#000000'
          }

          this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.EDITED_SPOTTING_AREA_HIGHLIGHT, {
            id: value,
            color: color
          })
        }
      },
      selectedSpottingAreaColor: {
        get() {
          return this.updatedSpottingAreaHighlight.color ?? this.$store.state.spottingAreas.highlightedSpottingArea.data.highlight_color ?? '#000000'
        },
        set(value) {
          this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.EDITED_SPOTTING_AREA_HIGHLIGHT, {
            id: this.selectedSpottingArea,
            color: value
          })
        }
      },
      availableSpottingAreas() {
        if (!this.spottingAreas || this.spottingAreas.length === 0) {
          return []
        }

        const spottingAreas = this.spottingAreas.map(SpottingArea => { // package is a reserved JS word
          return {
            value: SpottingArea.id,
            label: SpottingArea.name,
          }
        })

        spottingAreas.push({ value: 'none', label: '<None>' })

        return spottingAreas.sort((a, b) => a.label.localeCompare(b.label))
      },
      labelSaveSpottingAreasFocusOn() {
        if (this.saving) {
          return 'Saving...'
        }

        if (this.savingComplete) {
          return 'Saved!'
        }

        return 'Save'
      },
      hasPadding() {
        return this.panel.toLowerCase() !== 'ecosystem-reporting'
      },
      panel() {
        return this.$route.params.panel || 'ecosystem'
      },
      savedMessage() {
        if (!this.errors) {
          return this.success
        }

        const messages = this.errors[Object.keys(this.errors)[0]]

        var message = ''

        if (messages && messages.length > 0) {
          message = messages[0]
        }

        if (!message.length) {
          return this.success
        }

        return this.success + ': ' + message
      },
      fullPanel() {
        return ['users', 'maintenance-requests'].includes(this.panel)
      },
      isEditing() {
        switch (this.panel) {
          case 'ecosystem':
            return this.$route.hash != '#custom-scores'
            break
          case 'ecosystem-member-packages':
            return this.$route.hash === '#cta'
          case 'subscription':
          case 'publisher':
          case 'competitive':
          case 'developer':
          case 'custom-emails':
          case 'integrations':
            return true
            break
          default:
            return false
        }
      },
      title() {
        switch (this.panel) {
          case 'ecosystem':
            return 'Configuration'
          case 'subscription':
            return 'Settings'
          case 'developer':
            return 'Developer options'
          case 'publisher':
            return 'Permissions'
          case 'ecosystem-heartbeat':
            return 'Ecosystem Dashboard'
          case 'data-consumption':
            return 'Data Consumption'
          case 'ecosystem-reporting':
            return 'Open Call Report Dashboard'
          case 'custom-scores':
            return 'Custom Scores'
          // case 'keys': return 'Key management'
          case 'knowledge-sharing':
            return 'Reporting'
          case 'integrations':
            return 'Integrations'
          case 'custom-emails':
            return 'Custom email templates'
          case 'onboarding':
            return 'Onboarding Settings'
          case 'ecosystem-member-packages':
            return 'Ecosystem Member Packages'
          case 'spotting-areas':
            return this.spottingAreasLabel
          case 'billing-overview':
            return 'Billing Overview'
          // case 'services': return 'Services'
        }

        return this.panel && (this.panel[0].toUpperCase() + this.panel.slice(1))
      },
      info() {
        switch (this.panel) {
          case 'ecosystem':
            return 'General ecosystem settings to customize the look and feel of platform.'
          case 'data-consumption':
            return 'Your ecosystem will consume one actor credit for each automatically updated actor profile in the course of a month. You will be alerted when you consumed 90% of your actor credits.'
          case 'ecosystem-reporting':
            return 'Get insights on reporting.'
          case 'subscription':
            return 'Customize your DataScouts ecosystem to fit your needs.'
          case 'invitations':
            return 'Invite your team.'
          case 'knowledge-sharing':
            return 'Offer your members additional fields to fill in.'
          case 'competitive':
            return 'Overview of your portfolios'
          case 'publisher':
            return 'Configure the permissions of the different roles in your ecosystem.'
          case 'developer':
            return 'Developer options'
          case 'keys':
            return 'Manage API keys'
          case 'custom-scores':
            return 'Define a custom score based on different properties.'
          case 'services':
            return 'Keep track of the services that have been used'
          case 'integrations':
            return 'Integrate and monitor third party applications.'
          case 'custom-emails':
            return 'Configure a custom email template for a specific event. Make sure that the parameters used in the custom template are the same as in the default template.'
          case 'communities':
            if (this.$store.getters.isOwner) {
              return 'Create and manage your communities.'
            }

            return 'Manage your communities.'
          case 'spotting-areas':
            return `Create and manage your ${this.spottingAreasLabel}.`
          case 'billing-overview':
            return 'Overview of all payments made via the platform'
        }
      },
      warnings() {
        const config = this.form
        const warnings = []

        if (config.legendeStrict) {
          warnings.push('legacy update setting legendeStrict to false')
        }
        if (!config.legendeProperty) {
          warnings.push('legacy update setting legendeProperty to category')
        }
        if (config.zip_filter) {
          warnings.push('Disabling zip_filter for members')
        }
        if (config.country_filter) {
          warnings.push('Disabling country_filter for members')
        }
        if (!config.viewActorTypes) {
          warnings.push('viewActorTypes should not be empty!')
        }
        if (config.publisher && !config.publisher.viewActorTypes) {
          warnings.push('publisher.viewActorTypes should not be empty!')
        }

        return warnings
      }
    },
    methods: {
      fetchHighlightedSpottingArea() {
        this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT)
      },
      async updateHighlightedSpottingArea() {
        await this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.UPDATE_SPOTTING_AREA_HIGHLIGHT, {
          id: this.selectedSpottingArea,
          color: this.selectedSpottingAreaColor
        })

        await this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)

        this.$bus.emit('spottingAreaUpdated', { spottingAreaId: this.updatedSpottingAreaHighlight.id })
      },
      fetchSpottingAreas() {
        ManageSpottingAreas
          .get()
          .then(response => {
            this.spottingAreas = []

            response.map(c => {
              const spottingArea = { ...c }

              if (spottingArea.type === 'public') {
                this.spottingAreas.push(spottingArea)
              }
            })

            this.fetchHighlightedSpottingArea()
          })
          .catch(errors => {
            console.log(errors)
          })
      },
      fetchConfig() {
        fetchAndPrepareConfigForEditing().then(config => {
          this.form = config

          if (this.form.spottingAreasFocus === undefined) {
            this.form.spottingAreasFocus = 'overview'
          }

          if (this.form.showActorTabInTopBarNavigationSimplified === undefined) {
            this.form.showActorTabInTopBarNavigationSimplified = false
          }
        })
      },
      // Remove defaults and check viewActorTypes
      cleanRoleConfig(formData, parent) {
        formData = removeDefaultsFrom(formData, emptyConfig())

        // Don't publish actor types that are hidden for members or have been deprecated
        formData.viewActorTypes = (formData.viewActorTypes || parent.viewActorTypes)
          .filter(t => parent.viewActorTypes.includes(t) && t !== 'Community')

        return formData
      },
      async save(saveSpottingAreasFocusOnProgress = false) {
        this.success = ''
        const formData = removeDefaultsFrom(this.form, {})

        if (saveSpottingAreasFocusOnProgress) {
          this.saving = true;
          this.savingComplete = false;
        }

        // Notifications data is always available, even if nothing is set in the ecosystem config, so to make sure
        // the old Slack settings are respected when the notification settings panel isn't enabled, we have to delete
        // the notifications data here
        /* if (!this.isNotificationSettingsPanelEnabled()) {
          delete formData.notifications
        } */

        // Removes the deprecated 'Community' actor type from old configs
        if (formData.viewActorTypes) {
          formData.viewActorTypes = formData.viewActorTypes.filter(t => t !== 'Community')
        }

        if (formData.viewActorTypes && !formData.viewActorTypes.includes('LegalEntity')) {
          formData.viewActorTypes.unshift('LegalEntity')
        }

        if (formData.publisher) {
          formData.publisher = this.cleanRoleConfig(formData.publisher, formData)
        }

        if (formData.permissions && formData.permissions.member) {
          formData.permissions.member = this.cleanRoleConfig(formData.permissions.member, formData)
        }

        if (formData.portfolio_actor && typeof formData.portfolio_actor !== 'string') {
          formData.portfolio_actor = formData.portfolio_actor.id
        }
        // If the homepage sections is empty, we add them all to the settings
        if (formData.homepageSections == undefined || (formData.homepageSections && !formData.homepageSections.length > 0)) {
          formData.homepageSections = this.$store.getters.homepageSections
        }

        // If the knowledge-base section is enabled but there is no tags selected we don't save
        if (formData.enableHomepageKnowledgeBase) {
          if (formData.homepage_knowledge_base_tags == undefined || (formData.homepage_knowledge_base_tags && formData.homepage_knowledge_base_tags.length <= 0)) {
            this.errors = { homepage_knowledge_base_tags: 'The knowledge-base section must have tags selected' }
            return
          }
        }

        // If we selected filter tags but the knowledge-base section is not enabled
        /* if (formData.homepage_knowledge_base_tags && formData.homepage_knowledge_base_tags.length > 0 && !formData.enableHomepageKnowledgeBase) {
          this.errors = { enableHomepageKnowledgeBase: "The knowledge-base section must be enabled in order to save the knowledge-base filter tags" }
          return
        } */

        // If we added an image and but the knowledge-base section is not enabled
        /* if ((formData.homepage_knowledge_base_image && formData.homepage_knowledge_base_image.length > 0) && !formData.enableHomepageKnowledgeBase) {
          this.errors = { homepage_knowledge_base_image: "The knowledge-base section must be enabled in order to save the knowledge-base image" }
          return
        }

        // If we added a video but the knowledge-base section is not enabled
        if ((formData.embedded_knowledge_base_video_url && formData.embedded_knowledge_base_video_url.length > 0) && !formData.enableHomepageKnowledgeBase) {
          this.errors = { embedded_knowledge_base_video_url: "The knowledge-base section must be enabled in order to save the knowledge-base video" }
          return
        } */

        // we save the challenges dashboard value in the 'challengesAreEnable' setting
        if (formData.viewDashboard && this.config.viewDashboard && formData.viewDashboard.includes('challenges') !== this.config.challengesAreEnabled) {
          if (formData.viewDashboard.includes('challenges')) {
            formData.viewDashboard.splice(formData.viewDashboard.indexOf('challenges'))
            formData.challengesAreEnabled = true
          } else {
            formData.challengesAreEnabled = false
          }
        }

        // we save the message dashboard value in the 'message_board_add_on_enabled' setting
        if (formData.viewDashboard && this.config.viewDashboard && formData.viewDashboard.includes('message_dashboard') !== this.config.messageBoardsAreEnabled) {
          if (formData.viewDashboard.includes('message_dashboard')) {
            formData.viewDashboard.splice(formData.viewDashboard.indexOf('message_dashboard'))
            formData.messageBoardsAreEnabled = true
          } else {
            formData.messageBoardsAreEnabled = false
          }
        }

        delete formData.possibleCategories
        delete formData.categories
        delete formData.hexBorders
        delete formData.colors

        // Emit an even that the settings are being saved
        this.$bus.emit('save-settings')

        // Update enrichment & monitor portfolio configurations
        await updateMonthlyEnrichment(this.form.monthly_enrichment)

        if (this.form.enrichPortfolios) {
          await updatePortfolioEnrichmentScope({ portfolio_ids: this.form.enrichPortfolios })
        }

        if (this.form.newsMonitorPortfolios) {
          await updatePortfolioMonitorScope({ portfolio_ids: this.form.newsMonitorPortfolios })
        }

        if (formData.viewActorTypes) {
          const viewActorTypes = []

          formData.viewActorTypes.map(function (actorType) {
            if (!viewActorTypes.includes(actorType) && ['LegalEntity', 'Product', 'Person'].includes(actorType)) {
              viewActorTypes.push(actorType)
            }
          })

          formData.viewActorTypes = viewActorTypes
        }

        updateConfig(formData)
          .then((config) => {
            this.errors = {}

            if (saveSpottingAreasFocusOnProgress) {
              this.saving = false;
              this.savingComplete = true;

              setTimeout(() => {
                this.savingComplete = false;
              }, 2000)
            }

            if (config.challengesAreEnabled && !config.viewDashboard.includes('challenges')) {
              config.viewDashboard.push('challenges')
            }

            if (config.messageBoardsAreEnabled && !config.viewDashboard.includes('message_dashboard')) {
              config.viewDashboard.push('message_dashboard')
            }

            this.success = 'The configuration has been updated!'
            this.$store.commit('CONFIG_SET', inert(config))
            this.form = config
            this.$store.dispatch(LOCALIZATION_ACTION_TYPES.UPDATE_ENABLED_LOCALES)
            this.$store.dispatch(LOCALIZATION_ACTION_TYPES.UPDATE_NUMERIC_LOCALE)
          })
          .catch(errors => {
            this.success = 'Failed to save configuration'
            this.errors = errors

            // if this.savedMessage contains "The homepage image is"
            if (this.savedMessage.includes('The homepage image is') && document.getElementById('homepageKnowledgeBaseImageMessage')) {
              document.getElementById('homepageKnowledgeBaseImageMessage').innerHTML = this.savedMessage
            }
          })

        // prevent visual glitch while saving is in progress
        if (formData.challengesAreEnabled && !formData.viewDashboard.includes('challenges')) {
          formData.viewDashboard.push('challenges')
        }

        // prevent visual glitch while saving is in progress
        if (formData.messageBoardsAreEnabled && !formData.viewDashboard.includes('message_dashboard')) {
          formData.viewDashboard.push('message_dashboard')
        }
      }
    },
    mounted() {
      if (!this.isMember && !this.isPortfolioMember) {
        return this.$router.push('/')
      }

      this.$bus.on('spottingAreaUpdated', () => {
        this.fetchSpottingAreas()
      })

      if (this.isMember || this.isOwner) {
        this.fetchConfig()
      }
      this.fetchSpottingAreas()
    },
    beforeUnmount() {
      this.$bus.off('spottingAreaUpdated')
    },
    components: {
      ColorPicker,
      Dropdown,
      Checkbox, FormGroup,
      RadioButton,
      ActionBar,
      CustomEmails,
      CustomScores,
      DataConsumption,
      Developer,
      Ecosystem,
      EcosystemHeartbeat,
      EcosystemReporting,
      EcosystemMemberPackages,
      Integrations,
      Invitations,
      Keys,
      Onboarding,
      Publisher,
      Services,
      Subscription,
      Users,
      MaintenanceRequests,
      WithConfigurationTabs,
      Reporting,
      Exploration,
      Communities,
      SpottingAreas,
      BillingOverview
    },
  })
</script>
