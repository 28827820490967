<template>
  <div :class="`categorized-search-input categorized-search-input--layout-${layout}`">
    <div class="categorized-search-input__input">
      <DsInput
        icon="search"
        ref="searchInputContainer"
        v-model="query"
        :placeholder="placeholder"
        :inputStyles="getInputStyles()"
        @focus="handleSearchInputFocus"
        @blur="handleSearchInputBlur"
        @update:modelValue="handleSearchInputQuery"
      />
      <template v-if="displayedKeywords.length > 0">
        <div class="categorized-search-input__keywords" ref="keywordsContainer">
          <template v-for="(keyword, index) in displayedKeywordsConverted">
            <KeywordWithFacet
              v-if="index + 1 <= 10"
              :label="keyword.value"
              :model-value="keyword"
              removeable
              @remove="handleRemoveKeyword"
            />
          </template>
          <p style="padding-left: 15px; opacity: 0.7; font-family: 'Fira Sans', sans-serif;"
             v-if="displayedKeywords.length > 10">and {{ displayedKeywords.length - 10 }}
            other(s)</p>
        </div>
      </template>
      <template v-if="filtersActive">
        <div class="categorized-search-input__delete-all-keywords">
          <ds-button label="Delete all" variant="link" size="small" @click="handleClickDeleteAllKeywords"/>
        </div>
      </template>
    </div>
    <div class="categorized-search-input__results"
         v-if="areResultsVisible && filteredCategories.length > 0"
         :class="{ 'categorized-search-input__results--visible': areResultsVisible && filteredCategories.length > 0 }"
         :style="resultsContainerStyles" @mousedown.prevent="">
      <div class="categorized-search-input__category" v-for="category in filteredCategories">
        <div class="categorized-search-input__category-title">{{ categoryTitle(category) }}</div>
        <div class="categorized-search-input__option" v-for="option in category.options"
             @click.stop.prevent="handleSelectSearchOption(option)">
          <HighlightedText :string="renderOption(option)" :string-to-highlight="query"/>
        </div>
      </div>
    </div>
    <div v-else-if="areResultsVisible && filteredCategories.length === 0"
         class="categorized-search-input__results categorized-search-input__results--visible"
         :style="resultsContainerStyles" @mousedown.prevent="" style="text-align: center;">
      <p>No results found for "{{ query }}". You might have more success using the global search.
        <ds-button variant="secondary" size="extra-small" label="Open Global Search" @click="showGlobalSearch"/>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import HighlightedText from '../HighlightedText/HighlightedText.vue'
  import KeywordWithFacet from '../Keyword/KeywordWithFacet.vue'
  import { getAllReportFields } from '../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CategorizedSearchInput',
    components: { KeywordWithFacet, HighlightedText },
    props: {
      placeholder: String,
      categories: Array,
      layout: {
        type: String,
        default: 'default',
      },
      displayedKeywords: {
        type: Array,
        default: () => [],
      },
      caseSensitive: {
        type: Boolean,
        default: false,
      },
      renderOption: {
        type: Function,
        default: (option) => option,
      },
    },
    data() {
      return {
        query: '',
        resultsContainerStyles: {},
        areResultsVisible: false,
      }
    },
    computed: {
      filteredCategories() {
        if (!this.query) {
          return this.categories
        }

        const regex = new RegExp(this.query, this.caseSensitive ? 'g' : 'gi')

        return this.categories
          .map(category => ({
              ...category,
              options: category.options.filter(option => (this.renderOption(option)).match(regex)),
            }
          ))
          .filter(category => category.options.length > 0)
      },
      displayedKeywordsConverted() {
        return this.displayedKeywords.reverse()
      },
      filtersActive() {
        var simpleKeywords = (this.$store.state.filters.keywords || []).filter((keyword) => {
          return keyword.value !== '-' &&
            keyword.facet !== 'actor_type' &&
            keyword.facet !== 'legendProperty' &&
            !keyword.facet.includes('report_field_')
        })

        return (simpleKeywords.length > 0 && simpleKeywords.find((f) => f.facet !== 'actor_type')) ||
          this.$store.state.filters.employeeRange.employeeLowerBound ||
          this.$store.state.filters.employeeRange.employeeUpperBound ||
          this.$store.state.filters.totalFunding.minTotalFunding ||
          this.$store.state.filters.totalFunding.maxTotalFunding ||
          this.$store.state.filters.patentCountRange.patentCountMin ||
          this.$store.state.filters.patentCountRange.patentCountMax ||
          this.$store.state.filters.relationshipCountRange.relationshipCountMin ||
          this.$store.state.filters.relationshipCountRange.relationshipCountMax ||
          this.$store.state.filters.revenueRange.revenueMin ||
          this.$store.state.filters.revenueRange.revenueMax ||
          this.$store.state.filters.dateRanges.foundingDateEnd ||
          this.$store.state.filters.dateRanges.foundingDateStart ||
          this.$store.state.filters.dateRanges.fundingDateEnd ||
          this.$store.state.filters.dateRanges.fundingDateStart ||
          this.$store.state.filters.dateRanges.createdAtStart ||
          this.$store.state.filters.dateRanges.createdAtEnd ||
          this.$store.state.filters.dateRanges.claimedAtStart ||
          this.$store.state.filters.dateRanges.claimedAtEnd ||
          this.$store.state.filters.dateRanges.updatedAtStart ||
          this.$store.state.filters.dateRanges.updatedAtEnd ||
          this.$store.state.filters.customScoreRange.customScoreMin ||
          this.$store.state.filters.customScoreRange.customScoreMax ||
          this.$store.state.filters.completenessRange.completenessMin ||
          this.$store.state.filters.completenessRange.completenessMax ||
          this.$store.state.filters.portfolio ||
          this.reportFiltersActive
      },
      reportFiltersActive() {
        if (!getAllReportFields() || !this.$store.state.filters.reportFilters) {
          return false
        }

        var reportFilterEnabled = false

        for (var key in this.$store.state.filters.reportFilters) {
          if (this.$store.state.filters.reportFilters[key]) {
            var reportValue = this.$store.state.filters.reportFilters[key]

            if (Array.isArray(reportValue) && reportValue.length > 0) {
              reportFilterEnabled = true
            } else if (!Array.isArray(reportValue) && reportValue) {
              reportFilterEnabled = true
            }
          }
        }

        return reportFilterEnabled
      },
    },
    methods: {
      showGlobalSearch() {
        this.$bus.emit('showGlobalSearch', { query: this.query })
      },
      handleSelectSearchOption(option) {
        this.$emit('selectOption', { ...option })
        this.query = ''
        this.areResultsVisible = false
      },
      handleSearchInputFocus() {
        this.setResultsContainerStyles()
        this.areResultsVisible = Boolean(this.query)
      },
      handleSearchInputBlur() {
        setTimeout(() => {
          this.areResultsVisible = false
        }, 200)
      },
      handleSearchInputQuery() {
        this.areResultsVisible = Boolean(this.query)
        this.$emit('changeQuery', this.query)
      },
      handleRemoveKeyword(keyword) {
        this.$emit('removeKeyword', keyword)
      },
      handleClickDeleteAllKeywords() {
        this.$emit('clearKeywords')
      },
      getInputStyles() {
        if (this.$refs.keywordsContainer) {
          return {
            paddingLeft: `${34 + this.$refs.keywordsContainer.offsetWidth + 16}px`,
          }
        }
        return {
          paddingLeft: '34px',
        }
      },
      setResultsContainerStyles() {
        const { width } = this.$refs.searchInputContainer.$el.getBoundingClientRect()
        this.resultsContainerStyles = {
          width: `${width}px`,
        }
      },
      categoryTitle(category) {
        // in case the facet is report_field, set the title of the first option
        let title = category.title

        if (title.startsWith('report field')) {
          title = category.options[0].display
        }

        return title
      },
    },
    mounted() {
      // Use a timeout to force another update after the initial one so the keyword length can be properly calculated
      setTimeout(() => {
        this.$forceUpdate()
      }, 0)
    },
    watch: {
      displayedKeywords: {
        handler() {
          // Use a timeout to force another update after the initial one so the keyword length can be properly calculated
          setTimeout(() => {
            this.$forceUpdate()
          }, 0)
        },
      },
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins/icon-color";

  .categorized-search-input {
    position: relative;
  }

  .categorized-search-input--layout-large {
    .ds-input__input {
      height: 4rem;
    }

    .categorized-search-input__results {
      top: 4rem;
    }
  }

  .categorized-search-input__input {
    position: relative;
    @include iconColor($color-primary);
  }

  .categorized-search-input__keywords {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 2.5rem;
    bottom: 0;
    max-width: calc(100% - 380px);
  }

  .categorized-search-input__delete-all-keywords {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1rem;
    bottom: 0;
  }

  .categorized-search-input__results {
    display: flex;
    position: absolute;
    top: 36px;
    width: 20vw;
    min-width: 200px;
    padding: 1rem;
    z-index: 500;
    background-color: #fff;
    box-shadow: 0 3px 4px rgba(#000, 0.3);
    border: 1px solid rgba(#000, 0.3);
    border-top: 0;
    transition: all .2s;
    opacity: 0;
    pointer-events: none;
  }

  .categorized-search-input__results--visible {
    opacity: 1;
    pointer-events: all;
  }

  .categorized-search-input__category {
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .categorized-search-input__category-title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    color: $color-text-grey-light;
    margin-bottom: 1rem;
  }

  .categorized-search-input__option {
    padding: 0.25rem;
    font-weight: 500;
    font-size: 12px;
    background-color: transparent;
    transition: all .2s;
    margin-bottom: 0.5rem;

    &:hover,
    &.categorized-search-input__option--highlighted {
      cursor: pointer;
      color: $color-text-light;
      background-color: $color-primary;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
