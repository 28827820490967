<template>
  <div class="main">
    <div class="buttons" v-if="allowInput">
      <ds-button v-if="mode === 'remove'" icon="trash" :disabled="disabled" @click="denyActorRecommendation" class="actor-button__no-outline"/>
    </div>
    <div @click="addActor">
      <div class="header">
        <div class="title">
          {{ name }}
        </div>
      </div>
      <div class="actor-block__description" v-if="description">{{ description }}</div>
    </div>
  </div>
</template>

<script>
  import { toDateString } from '../../../util/date'

  export default {
    props: {
      mode: {
        type: String,
        validator: val => ['add', 'remove'].includes(val),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      id: String,
      name: String,
      description: String,
      url: String,
      allowInput: Boolean,
    },
    methods: {
      toDateString,
      addActor () {
        this.$emit('add', this.id)
      },
      denyActorRecommendation () {
        this.$emit('remove', this.id)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";

  .main {
    display: flex;
    margin-bottom: 20px;
  }

  .actor-button__no-outline.button {
    &:focus {
      box-shadow: none !important;
    }
  }

  .buttons {
    margin-right: 10px;
  }

  .header {
    display: flex;
    cursor: pointer;

    .title {
      font-weight: bold;
      margin-right: auto;
    }

    .date {
      margin-left: 20px;
      color: $color-text-grey-light;
      white-space: nowrap;
    }

    .url {
      white-space: nowrap;
    }
  }

  .actor-block__description {
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-height: 14px;
    max-height: 56px;
  }
</style>
