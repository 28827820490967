<template>
  <div class="mini-card" :style="extraCardStyles">
    <div class="mini-card__header">
      <div class="mini-card__title-container">
        <div class="mini-card__title">{{ title }}</div>
        <div class="mini-card__subtitle">{{ subtitle }}</div>
      </div>
      <div class="mini-card__avatar" v-if="$slots.avatar">
        <slot name="avatar"></slot>
      </div>
    </div>

    <div class="mini-card__content" v-if="content">{{ truncate(content, contentTruncateLimit) }}</div>
    <div class="mini-card__visual" v-if="visual" :style="{ backgroundImage: `url(${visual})` }"></div>
  </div>
</template>

<script lang="ts">
  import { truncate } from '../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'MiniCard',
    props: {
      title: String,
      subtitle: String,
      content: String,
      color: {
        type: String,
      },
      visual: String,
      fillAvailableHeight: {
        type: Boolean,
        default: false,
      },
      contentTruncateLimit: {
        type: Number,
        default: 200
      }
    },
    methods: {
      truncate,
    },
    computed: {
      extraCardStyles() {
        if (this.color) {
          return {
            borderLeft: `5px solid ${this.color}`
          }
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .mini-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 1rem;
    border: 1px solid #F5F5F5;
    box-shadow: 0 0 0 rgba(#000, 0.2);
    transition: box-shadow .2s;
    background-color: white;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 4px rgba(#000, 0.1);
    }
  }

  .mini-card--fill-available-height {
    height: 100%;
  }

  .mini-card__header {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }

  .mini-card__title-container {
    flex: 1;
  }

  .mini-card__title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
  }

  .mini-card__subtitle {
    text-transform: uppercase;
    font-size: 11px;
    color: $color-text-grey-light;
  }

  .mini-card__content {
    margin-bottom: 0;
    padding: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 300;
    transition: background-color .2s;
  }

  .mini-card__visual {
    height: 140px;
    width: 100%;
    margin-top: auto;
    background-size: cover;
    background-position-x: center;
  }
</style>
