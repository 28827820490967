<template>
  <div class="card--actor-info">
    <ds-button
      class="actor-preview__header__close-button"
      icon="remove"
      variant="minimal"
      @click="hidePreview"
    />
    <div class="user-overlay__header">
      <div class="user-overlay__title">Invite Users</div>
    </div>
    <div class="emails_container">
      <!--<h4 class="card__small__title">Add Emails</h4>-->
      <div>
        <form-group :label="label">
          <email-tag-input class="email_input" icon="email-outline" v-model="emails" placeholder="add users"
                           @update:modelValue="getEmails"/>

          <!-- Allow users to invite multiple portfolio members -->
          <!--autocomplete-tag-input
            v-else
            icon="email-outline"
            :tags="emails"
            @update:modelValue="getEmails"
            v-model="emails"
            :options="null"
            :addOnlyFromAutocomplete="false"
            :minInputLength="1"
            class="email_input"
            :max-tags="1"
            @input:raw="updateChosenEmail($event)"

            placeholder="Add user"
          />-->

          <ds-button
            tabindex="-1"
            icon="edit"
            variant="default"
            @click="toggleRoleSelector"
            class="invitations-role__icon"
          />
          <form class="form-horizontal magic-link-toolTip" v-bind:class="{ isVisible: showToolTip }">
            <form-group label="Role">
              <radio-button
                v-model="pickedRole"
                name="role"
                :label="roleList.text.owner"
                :val="roleList.value.owner"
                :required="true"
              />
              <radio-button
                v-model="pickedRole"
                name="role"
                :label="roleList.text.teamMember"
                :val="roleList.value.teamMember"
                :required="true"
              />
              <radio-button
                v-model="pickedRole"
                name="role"
                :label="roleList.text.portfolioMember"
                :val="roleList.value.portfolioMember"
                :required="true"
                v-if="canUserInvitePortfolioMember"
              />
              <radio-button
                v-model="pickedRole"
                name="role"
                :label="roleList.text.ecosystemMember"
                :val="roleList.value.ecosystemMember"
                :required="true"
                v-if="publisherEnabled"
              />
            </form-group>
          </form>
        </form-group>
      </div>
      <div>
        <form-group label="Add a personal invitation message" v-if="$store.getters.isAriadne">
          <rich-text-area v-model="invitationText"/>
        </form-group>

        <form-group label="Select (premium only) or create a Spotting Area" v-if="pickedRole === 'portfolio_member'">

          <autocomplete-tag-input
            :tags="selectedSpottingArea"
            :options="spottingAreas"
            :addOnlyFromAutocomplete="false"
            :minInputLength="1"
            class="dictionary-component__grow"
            :max-tags="1"
            @input:raw="updateChosenSpottingArea($event)"
            placeholder="Add or select a spotting area"
          />

        </form-group>

        <form-group label="Permissions on te spotting area (Note: the user will receive this permission for all portfolios in the spotting area)"
                    v-if="this.selectedSpottingArea && this.selectedSpottingArea[0]">
          <radio-button
            v-model="pickedPortfolioPermission"
            name="permission"
            label="Contributor"
            :val="roles.CONTRIBUTOR"
            :required="true"
            v-if="pickedRole === 'portfolio_member'"
          />
          <radio-button
            v-model="pickedPortfolioPermission"
            name="permission"
            label="Explorer"
            :val="roles.EXPLORER"
            :required="true"
            v-if="pickedRole === 'portfolio_member'"
          />
        </form-group>
      </div>
      <div class="form-group">
        <p class="form-group__success invitations-form__success" v-if="emailStatus.success">
          {{ emailStatus.success.new_users_invited }} new invitation(s) sent!
        </p>
        <p class="form-group__success invitations-form__error" v-if="emailStatus.success && emailStatus.success.invalid_emails">
          <template v-for="(message, email) in emailStatus.success.invalid_emails">
            {{ email }}: {{ message }}<br/>
          </template>
        </p>
        <p class="form-group__info invitations-form__error" v-else-if="emailStatus.error">
          {{ emailStatus.error }}
        </p>
      </div>
      <ds-button
        :disabled="sending"
        tabindex="-1"
        :label="sending ? 'Sending...' : 'Send'"
        variant="secondary"
        @click="sendEmails"
        class="send_email"
      />
    </div>
  </div>
</template>

<script>
  import Card from '../../components/Card/Card.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import TagInput from '../../components/Form/TagInput.vue'
  import WebDomainInput from '../../components/Form/WebDomainInput.vue'
  import EmailTagInput from '../../components/Form/EmailTagInput.vue'
  import DsInput from '../Form/DsInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import RadioButton from '../Form/RadioButton.vue'
  import RichTextArea from '../TextArea/RichTextArea.vue'
  import AutocompleteTagInput from '../Form/AutocompleteTagInput.vue'

  import { asyncConfig, sendInvitation } from '../../api/config.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import KEYCODES from '../../constants/keycodes'
  import { roles } from '../../store/modules/portfolios.js'
  import { SPOTTING_AREA_TYPES } from '@/store/modules/config.js'

  export default {
    name: 'invite-users-panel',
    data () {
      return {
        hidePanel: false,
        allowCurationEnabled: this.$store.getters.allowCuratedAdd, // check if the curation option in the settings is enabled
        // activeColor: '#FD916D',
        showToolTip: false, // controller for the tooltip
        pickedRole: null, // value of the selected role for when the emails are sent
        publisherEnabled: this.$store.getters.publisher, // check if it is in a private ecosystem
        roleList: {
          text: {
            owner: 'Owner',
            teamMember: 'Team Member',
            portfolioMember: 'Portfolio Member',
            ecosystemMember: 'Ecosystem Member',
          },
          value: {
            owner: 'owner',
            teamMember: 'member',
            portfolioMember: 'portfolio_member',
            ecosystemMember: 'actor',
          },
        },
        pickedPortfolioPermission: '',
        emailStatus: {
          success: '',
          error: null,
          busy: false,
        },
        emails: [], // Emails to send an invitation to
        invitationText: '',
        sending: false,
        spottingAreas: null,
        selectedSpottingArea: [],
        roles,
      }
    },
    computed: {
      label () {
        return 'Provide the email addresses of people you want to invite'
      },
      canUserInvitePortfolioMember () {
        var availableRoleNames = this.allowedRoles.map(role => role.value)

        return availableRoleNames.includes('portfolio_member') && this.$store.getters.hasAccessToNewSpottingAreas
      },
    },
    methods: {
      updateChosenEmail (event) {
        if (event && event[0] && event[0].text) {
          this.emails = [event[0].text]
        }
      },
      updateChosenSpottingArea (event) {
        this.selectedSpottingArea = event
      },
      transformSpottingAreaData () {
        const spottingAreas = this.$store.state.spottingAreas.listData.data

        const transformedSpottingAreas = []
        spottingAreas.forEach((spottingArea) => {
          if (spottingArea.type === SPOTTING_AREA_TYPES.PREMIUM) {
            transformedSpottingAreas.push({
              label: spottingArea.name,
              value: spottingArea.id,
            })
          }
        })

        this.spottingAreas = transformedSpottingAreas
      },
      normaliseRole (selectedRole) {
        if (window.ROLES) {
          return window.ROLES[selectedRole]
        }

        switch (selectedRole) {
          case 'actor':
            return 'Ecosystem Member'
          case 'portfolio_member':
            return 'Portfolio Member'
          case 'owner':
            return 'Owner'
          case 'member':
            return 'Team Member'
          default :
            return ''
        }
      },
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      getEmails (emails) {
        /*if (this.pickedRole === 'portfolio_member') {
          this.emails = [emails[0]]
        } else {*/
        this.emails = emails
        //}
        this.emailStatus.error = ''

        return this.emails
      },
      sendEmails () {
        if (this.emails.length < 1) {
          this.emailStatus.error = 'Please provide some email addresses to send the invitations to.'
          console.log(this.emailStatus.error)

          return
        }

        // Make sure the role is selected before sending the invitations
        if (!this.pickedRole) {
          this.showToolTip = true
          return
        }

        // Hide the selection pane of roles
        this.showToolTip = false

        var data = {}
        data.emails = this.emails
        data.role = this.pickedRole

        if (this.$store.getters.isAriadne) {
          data.invitationText = this.invitationText
        }

        data.invitationText = this.invitationText

        if (this.pickedRole === 'portfolio_member') {
          if (this.selectedSpottingArea && this.selectedSpottingArea[0]) {
            if (this.pickedPortfolioPermission !== roles.EXPLORER && this.pickedPortfolioPermission !== roles.CONTRIBUTOR) {
              this.emailStatus.error = 'Please provide the permission for the selected spotting area.'
              return
            }

            data.spottingArea = {}
            data.spottingArea.portfolio_permission = this.pickedPortfolioPermission
            data.spottingArea.label = this.selectedSpottingArea[0].text ? this.selectedSpottingArea[0].text : null
            data.spottingArea.value = this.selectedSpottingArea[0].value ? this.selectedSpottingArea[0].value : null
            data.spottingArea.type = SPOTTING_AREA_TYPES.PREMIUM
          }
        }

        if (this.pickedRole !== null && this.emails.length > 0) {
          this.sending = true

          sendInvitation(data)
            .then(response => {
              this.sending = false
              this.emailStatus.error = null
              this.emailStatus.success = response
              this.emails = []
              this.pickedRole = null
              this.invitationText = ''
              this.selectedSpottingArea = []
              this.pickedPortfolioPermission = 'contributor'

              this.$bus.emit('invitationsAdded')
            })
            .catch(error => {
              this.sending = false
              this.emailStatus.error = error.message
            })
        }
      },
      toggleRoleSelector () {
        this.showToolTip = !this.showToolTip
      },
    },
    created () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      this.transformSpottingAreaData()
    },
    watch: {
      pickedRole (role) {
        this.showToolTip = false

        if (role === 'portfolio_member') {
          if (this.emails && this.emails.length > 0) {
            this.emails = [this.emails[0]]
          }
        }
      },
    },
    components: {
      RichTextArea,
      Card,
      Keyword,
      TagInput,
      WebDomainInput,
      EmailTagInput,
      DsInput,
      FormGroup,
      RadioButton,
      AutocompleteTagInput,
    },
    mixins: [asyncConfig],
  }
</script>
