<template>
  <div class="simplified-community-card" :style="{'background-color': backgroundColor}"
       :class="variant">
    <div class="simplified-community-card__image_content" :class="variant">
      <div class="simplified-community-card__image" v-if="image && !fullImage"
           :style="`background-image: url('${image}');background-size: contain;`">
      </div>
      <div v-else-if="image && fullImage" class="simplified-community-card__image"
           :style="`background-image: url('${image}');background-size: cover;`">
      </div>
    </div>
    <div class="simplified-community-card__content" :class="variant">
      <h1 class="simplified-community-card__title" :class="variant">{{ title }}</h1>
      <p>{{ stripHtmlTagsWithSpaces(description) }}</p>
      <a v-if="variant" class="simplified-community-card__button" :class="variant" style="cursor: pointer;"
         @click="goToPage(href)">{{ buttonText }}</a>
      <a v-else class="simplified-community-card__button" :href="href">{{ buttonText }}</a>
    </div>
  </div>
</template>


<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'
  import MODAL_IDS from '@/constants/modal-ids'
  import { stripHtmlTagsWithSpaces } from '@/util/helpers.js'

  export default {
    props: {
      image: String,
      title: String,
      description: String,
      href: String,
      buttonText: String,
      backgroundColor: {
        type: String,
        default: '#F2F9FE',
      },
      fullImage: {
        type: Boolean,
        default: false,
      },
      variant: {
        type: String,
        default: '',
        validator: (value) => ['', 'spacewerx', 'agritech', 'ec-health'].includes(value),
      },
    },
    computed: {
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
    },
    data: () => {
      return {}
    },
    methods: {
      stripHtmlTagsWithSpaces,
      goToPage (href) {
        if (!this.isLoggedIn) {
          return this.showLoginModal()
        }

        return this.$router.push(href)
      },
      showLoginModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
    },
  }
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .simplified-community-card {
    filter: drop-shadow(0 3px 6px rgba(#000, 0.16));
    max-width: 400px;
    width: auto;
    min-height: 400px;
    border-radius: 8px;
    padding: 20px;

    .simplified-community-card__image_content.agritech,
    .simplified-community-card__image_content.ec-health,
    .simplified-community-card__image_content.spacewerx {
      padding-top: 0px !important;

      .simplified-community-card__image {
        width: 100%;
        height: 200px;
        border-radius: 8px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }
    }

    .simplified-community-card__content.ec-health,
    .simplified-community-card__content.spacewerx
    .simplified-community-card__content.agritech {
      .simplified-community-card__button {
        background: black;
        padding: 10px 20px;
        border-radius: 5px;
        color: white;
        text-decoration: none;
      }
    }

    .simplified-community-card__content.ec-health {
      .simplified-community-card__button {
        background: var(--primary);
      }
    }

    .simplified-community-card__content.agritech {
      .simplified-community-card__button {
        &:hover {
          background-color: $agritech-blue;
        }
      }
    }

    .simplified-community-card__content.spacewerx {
      .simplified-community-card__button {
        &:hover {
          background-color: $spacewerx-blue;
        }
      }
    }

    .simplified-community-card__content.ec-health {
      .simplified-community-card__button {
        &:hover {
          background-color: var(--primary-light);
        }
      }
    }


    .simplified-community-card__image_content {
      padding-top: 40px;

      @media (max-width: 768px) {
        padding-top: 10px;
      }

      .simplified-community-card__image {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }
    }

    .simplified-community-card__content {
      margin-top: 20px;

      :deep(ul) {
        list-style-type: disc !important;
        margin-left: 15px;

        :deep(li) {
          list-style-type: disc !important;
        }
      }

      .agritech {
        color: $agritech-blue;
      }

      .spacewerx {
        color: $spacewerx-blue;
      }

      .simplified-community-card__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%; /* Adjust to the parent's width */
      }

      h1 {
        min-height: 45px;
        color: #21244A;
        font-size: 18px;
        text-transform: uppercase;
      }

      p {
        color: #707070;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        font-size: 14px;
        height: 105px;
      }
    }
  }

</style>
