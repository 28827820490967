import { createJSONRequest } from '@/api/create-request'

export const createPaymentLink = (token, packageName, price, credits) => createJSONRequest({
  url: `/api/billing/purchase-actor-credits`,
  data: {
    token: token,
    package: packageName,
    price: price,
    credits: credits,
  },
})

export const fetchBillingPayments = () => createJSONRequest({
  url: `/api/billing/payments`,
  method: 'GET',
})

export const checkPayments = () => createJSONRequest({
  url: `/api/billing/check-payments`,
  method: 'GET',
})

export const downloadInvoice = (invoiceId) => createJSONRequest({
  url: `/api/billing/invoice/${invoiceId}`,
  method: 'GET',
})
