export const MUTATION_TYPES = {
  SHOW_ECOSYSTEM_PANEL: 'UI/SHOW_ECOSYSTEM_PANEL',
  HIDE_ECOSYSTEM_PANEL: 'UI/HIDE_ECOSYSTEM_PANEL',
  SET_NAVIGATION_VISIBILITY: 'UI/SET_NAVIGATION_VISIBILITY',
  TOGGLE_ECOSYSTEM_PANEL: 'UI/TOGGLE_ECOSYSTEM_PANEL',

  SHOW_ACTOR_PREVIEW_PANEL: 'UI/SHOW_ACTOR_PREVIEW_PANEL',
  HIDE_ACTOR_PREVIEW_PANEL: 'UI/HIDE_ACTOR_PREVIEW_PANEL',

  SHOW_SIDE_PANEL: 'UI/SHOW_SIDE_PANEL',
  HIDE_SIDE_PANEL: 'UI/HIDE_SIDE_PANEL',
  TOGGLE_SIDE_PANEL: 'UI/TOGGLE_SIDE_PANEL',

  SHOW_GLOBAL_SEARCH: 'UI/SHOW_GLOBAL_SEARCH',
  HIDE_GLOBAL_SEARCH: 'UI/HIDE_GLOBAL_SEARCH',
  TOGGLE_GLOBAL_SEARCH: 'UI/TOGGLE_GLOBAL_SEARCH',
  UPDATE_GLOBAL_SEARCH_QUERY: 'UI/UPDATE_GLOBAL_SEARCH_QUERY',

  SET_MODAL_CONTEXT: 'UI/SET_MODAL_CONTEXT',
  SHOW_MODAL: 'UI/SHOW_MODAL',
  HIDE_MODAL: 'UI/HIDE_MODAL',

  TOGGLE_OFFICES: 'UI/TOGGLE_OFFICES',

  SET_LEGEND_COORDINATES: 'UI/SET_LEGEND_COORDINATES',

  SET_PREVENT_DATALAB_NAVIGATION: 'UI/SET_PREVENT_DATALAB_NAVIGATION',

  SET_ACTOR_DETAIL_CONNECTIONS_VIEW_TYPE: 'FILTERS/SET_ACTOR_DETAIL_CONNECTIONS_VIEW_TYPE',

  DISABLE_SUB_NAV_HOVER: 'UI/DISABLE_SUB_NAV_HOVER',
  ENABLE_SUB_NAV_HOVER: 'UI/ENABLE_SUB_NAV_HOVER',
}

export const mutations = {
  [MUTATION_TYPES.SHOW_ECOSYSTEM_PANEL] (state) {
    state.ecosystemSwitcher.isVisible = true
  },
  [MUTATION_TYPES.HIDE_ECOSYSTEM_PANEL] (state) {
    state.ecosystemSwitcher.isVisible = false
  },
  [MUTATION_TYPES.SET_NAVIGATION_VISIBILITY] (state, isVisible) {
    state.navigation.isVisible = isVisible
  },
  [MUTATION_TYPES.TOGGLE_ECOSYSTEM_PANEL] (state) {
    state.ecosystemSwitcher.isVisible = !state.ecosystemSwitcher.isVisible
  },

  [MUTATION_TYPES.DISABLE_SUB_NAV_HOVER] (state) {
    state.subNavHoverEnabled = false
  },
  [MUTATION_TYPES.ENABLE_SUB_NAV_HOVER] (state) {
    state.subNavHoverEnabled = true
  },

  [MUTATION_TYPES.SHOW_ACTOR_PREVIEW_PANEL] (state) {
    state.actorPreview.isVisible = true
  },
  [MUTATION_TYPES.HIDE_ACTOR_PREVIEW_PANEL] (state) {
    state.actorPreview.isVisible = false
  },

  [MUTATION_TYPES.SHOW_SIDE_PANEL] (state, sidePanelConfig) {
    state.sidePanel.isVisible = true
    state.sidePanel.selectedComponent = sidePanelConfig.component
    state.sidePanel.metaData = sidePanelConfig.metaData
    state.sidePanel.expanded = true
  },
  [MUTATION_TYPES.HIDE_SIDE_PANEL] (state) {
    state.sidePanel.isVisible = false
    state.sidePanel.selectedComponent = ''
    state.sidePanel.metaData = {}
    state.sidePanel.expanded = true
  },
  [MUTATION_TYPES.TOGGLE_SIDE_PANEL] (state, sidePanelConfig) {
    state.sidePanel.expanded = sidePanelConfig
  },

  [MUTATION_TYPES.SHOW_GLOBAL_SEARCH] (state) {
    state.globalSearch.isVisible = true
  },
  [MUTATION_TYPES.HIDE_GLOBAL_SEARCH] (state) {
    state.globalSearch.isVisible = false
  },
  [MUTATION_TYPES.TOGGLE_GLOBAL_SEARCH] (state) {
    state.globalSearch.isVisible = !state.globalSearch.isVisible
  },
  [MUTATION_TYPES.UPDATE_GLOBAL_SEARCH_QUERY] (state, query) {
    state.globalSearch.query = query
  },
  [MUTATION_TYPES.SET_MODAL_CONTEXT] (state, context) {
    state.modalContext = context
  },
  [MUTATION_TYPES.SHOW_MODAL] (state, modalId) {
    state.visibleModals = [modalId]
  },
  [MUTATION_TYPES.HIDE_MODAL] (state, modalId) {
    state.visibleModals = state.visibleModals.filter(m => m !== modalId)
  },

  [MUTATION_TYPES.TOGGLE_OFFICES] (state) {
    state.showOffices = !state.showOffices
  },

  [MUTATION_TYPES.SET_ACTOR_DETAIL_CONNECTIONS_VIEW_TYPE] (state, value) {
    if (['nodes', 'list'].includes(value)) {
      state.actorDetailConnectionsViewType = value
    }
  },

  // Will set the coordinates of the legend box
  [MUTATION_TYPES.SET_LEGEND_COORDINATES] (state, legendBoxProperties) {
    var legendBoxName = Object.keys(legendBoxProperties)[0]
    var legendBoxCoordinates = Object.values(legendBoxProperties)[0]

    // We filter out all legends that are not the currently selected one
    state.legendBoxProperties = state.legendBoxProperties.filter(legend => legend.name !== legendBoxName).slice()
    // We push the new values of the currently selected legend
    state.legendBoxProperties.push({ name: legendBoxName, coordinates: legendBoxCoordinates })
  },

  [MUTATION_TYPES.SET_PREVENT_DATALAB_NAVIGATION] (state, preventDatalabNavigation) {
    state.preventDatalabNavigation = preventDatalabNavigation
  },
}

export default {
  state: {
    globalSearch: {
      isVisible: false,
      query: '',
    },
    actorClaimModal: {
      isVisible: false,
    },
    actorPreview: {
      isVisible: false,
    },
    sidePanel: {
      isVisible: false,
      expanded: true,
      selectedComponent: null,
      metaData: null,
    },
    ecosystemSwitcher: {
      isVisible: false,
    },
    navigation: {
      isVisible: true,
    },
    subNavHoverEnabled: true,
    modalContext: null,
    showOffices: document && document.body ? document.body.clientWidth < 768 : false,
    visibleModals: [],
    legendBoxProperties: [], // Will contain properties referencing each individual legend box in the application
    preventDatalabNavigation: false,
  },
  getters: {
    isMobile () {
      return window && window.outerWidth < 768
    },
    legendBoxProperties (state) {
      // Will return an array containing objects
      // Each object represents a legend box with its properties "name" and "coordinates"
      return state.legendBoxProperties
    },

  },
  mutations,
}
