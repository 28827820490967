<template>
  <div>
    <h2 class="h2">Views</h2>
    <!-- DO NOT USE V-IF BECAUSE OF DOM INSERTION ERRORS - update: this is probably solved by wrapping every block of code inside a "slot" div, with 1 root div -->
    <form-group label="Directories" :style="{display: form.viewActorTypes ? 'block' : 'none'}">
      <checkbox model-value="LegalEntity" inline disabled val="LegalEntity"/>
      <checkbox v-model="form.viewActorTypes" inline multiple val="Person"/>
      <checkbox v-model="form.viewActorTypes" inline multiple val="Product"/>
    </form-group>
    <form-group label="Directories" :style="{display: form.viewActorTypes ? 'none' : 'block'}">
      <checkbox :model-value="false" @update:modelValue="form.viewActorTypes = ['LegalEntity', 'Person']" label="Enable actor type directories"/>
    </form-group>
    <form-group label="Directory views">
      <checkbox v-model="form.viewList" inline label="List"/>
      <checkbox v-model="form.viewGallery" inline label="Gallery"/>
      <checkbox v-model="form.viewMap" inline label="Map"/>
    </form-group>
    <form-group label="List view columns" v-if="form.viewList">
      <dropdown multiple v-model="form.viewList" :options="viewListOptions"/>
    </form-group>
    <form-group label="Dashboards">
      <dropdown multiple v-model="form.viewDashboard" :options="viewDashboardOptions"/>
    </form-group>
  </div>
</template>

<script>
  import Checkbox from '../../Form/Checkbox.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import TagInput from '../../Form/TagInput.vue'
  import { actorTypeNav, viewAnalyticsOptions, viewDashboardOptions as rawDashboardOptions, viewListOptions } from '../../../constants/config'

  import UiMixin from '../../../util/UiMixin'
  import _uniq from 'lodash/uniq.js'

  export default {
    name: 'views',
    props: ['form'],
    data () {
      return {
        actorTypeNav,
        viewAnalyticsOptions,
        rawDashboardOptions,
        viewListOptions,
      }
    },
    computed: {
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      viewDashboardOptions () {
        let options = rawDashboardOptions.slice(0)

        // Remove deprecated options
        options = options.filter(d => !['competitive-overview', 'country'].includes(d.value))

        if (!this.form.hasScores || !this.$store.getters.isScoresEnabled || !this.$store.getters.hasAccessToScores) {
          options = options.filter(d => !['scores-list', 'competitive-landscape', 'digital-footprint', 'business-size'].includes(d.value))
        }

        if (!['pro', 'agency'].includes(this.$store.getters.packageType) && this.$store.getters.packageType != 'standard') { // Deprecated, everything has been migrated to "standard"
          options = options.filter(d => !['knowledge-base', 'funding-list', 'scores-list', 'funding'].includes(d.value))
        } else if (this.$store.getters.packageType == 'standard' && !this.$store.getters.hasAccessToSensing) {
          // options = options.filter(d => !['knowledge-base'].includes(d.value))
        }

        if (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring) {
          /*options.push({ value: 'explore-content', icon: 'tag', label: 'Exploration' })*/ // no need to push this anymore since its in the default settings and never filtered out
          options.push({ value: 'media-buzz', icon: 'buzz', label: 'Media Buzz' })

          if (this.hasAccessToNewsOverview) {
            // options.push({ value: 'news-overview', icon: 'buzz', label: 'News Overview' })
          }
        }

        if (!this.$store.getters.hasAccessToSensing) {
          options = options.filter(d => !['buzz'].includes(d.value))
        }

        if (!this.isDeveloper) {
          options = options.filter(d => !['dynamic-dashboard'].includes(d.value))
          options = options.filter(d => !['instant-monitoring'].includes(d.value))
        }

        return _uniq(options)
      },
      hasAccessToNewsOverview () {
        return this.$store.getters.isUmicore ||
          ['carboncapture-monitoring', 'cas-monitoring', 'local', 'devo', 'colruyt-farma', 'martech', 'startup-ecosystem', 'ecosystems2030', 'competitive-intelligence'].includes(this.$store.getters.ecosystemName.toLowerCase())
      },
    },
    watch: {
      'form.viewList': {
        handler () {
          if (this.form.viewList === true) {
            this.form.viewList = ['city', 'industries', 'stage', 'total_funding']
          }
        }
      }
    },
    mixins: [UiMixin],
    components: {
      TagInput,
      DsTextarea,
      ImageInput,
      Dropdown,
      Checkbox,
    },
  }
</script>
