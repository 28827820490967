<template>
  <div id="introjs-claimed-actor-profile-secondtopbar-home" :style="'position: relative !important'">
    <div
      class="second-top-bar-parent" :class="{'second-top-bar-parent-blurred': hasBlurredSecondTopBar}"
      :style="{ backgroundColor: backgroundColorStyle, left: leftStyle, borderBottom: borderBottomStyle }"
    >
      <div
        class="second-top-bar"
        :class="{'with-breadcrumbs': canShowBreadcrumbs, 'second-top-bar-foodleap': isFoodleap}"
      >
        <template v-if="canShowBreadcrumbs">
          <breadcrumbs/>
        </template>

        <template v-if="showRightHandSideTabs">
          <div class="top-bar__links-parent">
            <div
              class="top-bar__links" v-if="canShowBreadcrumbs && paramId && community.name"
              :style="{ maxWidth: rightHandSideMaxWidthStyle, marginRight: marginRightStyle }"
            >
              <ds-button
                :class="{'second-top-bar__link-active': subNavSection === ''}"
                v-tooltip.top="$t('tooltip_overview', {ecosystem: community.name})"
                :to="'/communities/' + paramId" label="Overview" variant="minimal"
              />
              <ds-button
                v-if="doesUserHaveFullAccessToCommunity"
                v-tooltip.top="$t('tooltip_community_members')"
                :class="{'second-top-bar__link-active': subNavSection === 'members'}"
                :label="$t('community_section_members')"
                variant="minimal"
                @click="viewCommunityMembers"
              />

              <ds-button
                v-if="doesUserHaveFullAccessToCommunity"
                v-tooltip.top="$t('tooltip_actors_simplified')"
                :class="{'second-top-bar__link-active': subNavSection === 'actors'}"
                :label="$t('community_section_actors')"
                variant="minimal"
                @click="viewCommunityActors"
              />

              <ds-button
                v-if="displayCommunityAnnouncements"
                variant="minimal"
                :class="{'second-top-bar__link-active': subNavSection === 'announcements'}"
                v-tooltip.top="$t('tooltip_announcements_simplified', {announcements: announcementsLabel})"
                :label="announcementsLabel"
                @click="viewCommunityAnnouncements"
                id="introjs-communities-detailpage-message-board"
              />

              <ds-button
                v-if="displayCommunityAnnouncements"
                id="introjs-communities-detailpage-message-board"
                variant="minimal"
                v-tooltip.top="$t('tooltip_events_simplified', {events: eventsLabel})"
                :class="{'second-top-bar__link-active': subNavSection === 'events'}"
                :label="eventsLabel"
                @click="viewCommunityEvents"
              />

              <ds-button
                v-if="displayCommunityChallenges"
                variant="minimal"
                v-tooltip.top="$t('tooltip_challenges_simplified', {challenges: challengesLabel})"
                :badge-count="totalNewChallengesCount"
                :class="{'second-top-bar__link-active': checkIsActive('challenges')}"
                :to="'/communities/' + paramId + '/challenges'"
                :label="challengesLabel"
                @click="trackHeapEvent('topbar.challengesSimplified')"
              />

              <ds-button
                v-if="displayCommunityKnowledgeBase"
                variant="minimal"
                v-tooltip.top="$t('tooltip_knowledge_base_simplified', {knowledge_base: knowledgeBaseLabel})"
                id="introjs-communities-detailpage-knowledge-base"
                :class="{'active': subNavSection === 'knowledge-base'}"
                :to="'/communities/' + paramId + '/knowledge-base'"
                :label="$t('knowledge_base_default_value')"
              />
            </div>

            <div class="top-bar__links" v-else>
              <ds-button
                to="/dashboards/home"
                :class="{'second-top-bar__link-active': checkIsActive('home')}"
                @click="trackHeapEvent('topbar.home')"
                v-tooltip.top="$t('tooltip_home', {ecosystem: ecosystemName})"
                :label="$t('top_bar_home')"
                variant="minimal"
                id="introjs-after-onboarding-secondtopbar"
              ></ds-button>
              <ds-button
                v-if="hasAccessToCommunities && communities.length > 0"
                to="/communities"
                :class="{'second-top-bar__link-active': checkIsActive('communities')}"
                :label="$t('communities_generic')"
                variant="minimal"
                id="introjs-after-onboarding-secondtopbar-communities"
              ></ds-button>
              <ds-button
                v-if="canViewSpottingAreas && spottingAreas.length > 0 && isNotHighlightedSpottingArea"
                to="/spotting-areas"
                :class="{'second-top-bar__link-active': checkIsActive('spotting-areas')}"
                v-tooltip.top="onboardingTextConfig.spottingAreasSimplifiedSubtitle"
                :label="spottingAreasLabel"
                variant="minimal"
                id="introjs-after-onboarding-secondtopbar-spotting-areas"
              ></ds-button>
              <ds-button
                v-if="(showActorsTabWhenSpottingAreasAreEnabled || (!canViewSpottingAreas || spottingAreas.length === 0)) && viewActorTypes.includes('LegalEntity')"
                :badge-count="totalNewActorsCount"
                to="/actors-simplified"
                :class="{'second-top-bar__link-active': checkIsActive('actors')}"
                @click="trackHeapEvent('topbar.actorsSimplified')"
                v-tooltip.top="$t('tooltip_actors_simplified')"
                :label="onboardingTextConfig.actorsSecondTopBarTitle ?? $t('top_bar_actors')"
                variant="minimal"
              ></ds-button>
              <ds-button
                v-if="viewActorTypes.includes('Product')"
                to="/products-simplified"
                :class="{'second-top-bar__link-active': checkIsActive('products')}"
                @click="trackHeapEvent('topbar.productsSimplified')"
                v-tooltip.top="$t('tooltip_products_simplified', {products: productsLabel})"
                :badge-count="totalNewProductsCount"
                :label="productsLabel"
                variant="minimal"
                id="introjs-after-onboarding-secondtopbar-next-to-products"
              ></ds-button>
              <ds-button
                v-if="displayChallenges"
                :badge-count="totalNewChallengesCount"
                :class="{'second-top-bar__link-active': checkIsActive('challenges')}"
                @click="trackHeapEvent('topbar.challengesSimplified')"
                v-tooltip.top="$t('tooltip_challenges_simplified', {challenges: challengesLabel})"
                to="/challenges"
                :label="challengesLabel"
                variant="minimal"
              ></ds-button>
              <ds-button
                to="/announcements-simplified"
                v-tooltip.top="$t('tooltip_announcements_simplified', {announcements: announcementsLabel})"
                :class="{'second-top-bar__link-active': checkIsActive('announcements')}"
                @click="trackHeapEvent('topbar.announcementsSimplified')"
                :badge-count="totalNewNotificationsCount"
                v-if="displayAnnouncements"
                :label="announcementsLabel"
                variant="minimal"
                id="introjs-after-onboarding-message-board"
              ></ds-button>
              <ds-button
                v-if="displayEvents"
                to="/events-simplified"
                v-tooltip.top="$t('tooltip_events_simplified', {events: eventsLabel})"
                :class="{'second-top-bar__link-active': checkIsActive('events')}"
                @click="trackHeapEvent('topbar.eventsSimplified')"
                :badge-count="totalNewNotificationsCount"
                :label="eventsLabel"
                variant="minimal"
                id="introjs-after-onboarding-message-board"
              ></ds-button>
              <ds-button
                :to="showIntelligenceUpgradeCTA ? '/profile/subscription#upgrade' : '/intelligence-simplified'"
                v-tooltip.top="showIntelligenceUpgradeCTA ? intelligenceCtaUpgradeText : $t('tooltip_intelligence_simplified')"
                :class="{'second-top-bar__link-active': checkIsActive('intelligence')}"
                @click="trackHeapEvent('topbar.intelligenceSimplified')"
                v-if="displayExploreContent || showIntelligenceUpgradeCTA"
                :label="$t('top_bar_intelligence')"
                :icon="showIntelligenceUpgradeCTA ? 'lock' : ''"
                variant="minimal"
                id="introjs-after-onboarding-intelligence"
              ></ds-button>
              <ds-button
                to="/dashboards/knowledge-base"
                v-tooltip.top="$t('tooltip_knowledge_base_simplified', { knowledge_base: knowledgeBaseLabel})"
                :class="{'second-top-bar__link-active': checkIsActive('knowledge-base')}"
                @click="trackHeapEvent('topbar.knowledgeBaseSimplified')"
                v-if="displayKnowledgeBase"
                :label="knowledgeBaseLabel"
                variant="minimal"
                id="introjs-after-onboarding-knowledge-base"
              ></ds-button>
              <!--              <ds-button
                              to="/dashboards/taxonomy"
                              v-tooltip.top="'View the Taxonomy Classification'"
                              :class="{'second-top-bar__link-active': checkIsActive('classification')}"
                              v-if="showTaxonomyNavigator"
                              label="Taxonomy"
                              variant="minimal"
                            >
                            </ds-button>-->
              <!--Button with link to BAwear landing page -->
              <ds-button
                href="https://ellieconnect.com/bawear-score-tool"
                v-if="false && $store.getters.isAriadne"
                label="BAwear Score"
                variant="minimal"
              ></ds-button>
              <ds-button
                href="https://forms.gle/CMcPny6GPBcmrBf56"
                v-if="$store.getters.isGreentex"
                label="Contact us"
                variant="minimal"
              ></ds-button>
              <ds-button
                class="highlight-spotting-area-button"
                icon="location-marker"
                :icon-fill-color="'#ffffff'"
                v-if="highlightedSpottingArea.id"
                :style="{backgroundColor: highlightedSpottingArea.highlight_color}"
                @click="goToHighlightedSpottingAreaDetail"
                :label="highlightedSpottingArea.name"
              ></ds-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
  import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics.js'
  import { SiteTemplate } from '../../store/modules/config.js'
  import UiMixin from '../../util/UiMixin'
  import escapeHTML from '../../util/escapeHTML'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import Breadcrumbs from './Breadcrumbs.vue'
  import NavigationMixin from '../../util/NavigationMixin.js'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { defineComponent } from 'vue'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants.js'
  import CommunityMixin from '@/util/CommunityMixin'

  export default defineComponent({
    props: {
      notifications: false,
      blur: {
        type: Boolean,
      },
    },
    data () {
      return {
        dropdown: false,
        actorSuggestionSettings: {
          api: {
            url: '/api/actors?name={query}',
            onResponse: data => ({
              results: Object.values(data).map(company => ({
                title: escapeHTML(company.name),
                data: company,
              })),
            }),
          },
        },
        selection: [],
        errors: {},
        portfolioList: null,
        hasFavouritePortfolios: false,
        query: '',
        rightHandSideMaxWidthStyle: '100%',
        marginRightStyle: '5px',
        backgroundColorStyle: 'white',
        borderBottomStyle: '1px solid #CECECE',
        leftStyle: 0,
      }
    },
    computed: {
      showTaxonomyNavigator () {
        return this.$store.getters.isEcHealth
      },
      isNotHighlightedSpottingArea () {
        if (this.spottingAreas.length > 1) {
          return true
        }

        if (this.spottingAreas.length === 0) {
          return false
        }

        return this.spottingAreas[0].id !== this.highlightedSpottingArea.id
      },
      highlightedSpottingArea () {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      hasBlurredSecondTopBar () {
        return false
      },
      isFoodleap () {
        return this.$store.getters.isFoodleap
      },
      showRightHandSideTabs () {
        if (this.isCommunitiesBeingVisited) {
          return true
        }

        if (this.$store.getters.isOwner || this.$store.getters.isMember || this.$store.getters.isPortfolioMember) {
          return false
        }

        return this.isSimplifiedTemplateUsed
      },
      isCommunitiesBeingVisited () {
        return [
          'communities',
          'community-detail',
        ].includes(this.$route.name)
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      community () {
        if (this.$route.name === 'community-detail') {
          return this.$store.state.communities.detail.data
        }

        return {}
      },
      spottingArea () {
        if (this.$route.name === 'spotting-area-detail') {
          return this.$store.state.spottingAreas.detail.data
        }

        return {}
      },
      showActorsTabWhenSpottingAreasAreEnabled () {
        return this.canViewSpottingAreas && window.config.showActorTabInTopBarNavigationSimplified
      },
      canViewSpottingAreas () {
        return this.$store.getters.hasAccessToNewSpottingAreas
      },
      paramId () {
        if (this.$route.name === 'community-detail') {
          return this.$route.params.communityId
        }

        if (this.$route.name === 'spotting-area-detail') {
          return this.$route.params.spottingAreaDetail
        }
      },
      subNavSection () {
        return this.$route.params.section
      },
      isMember () {
        return this.$store.getters.isMember
      },
      ecosystemName () {
        return this.$store.getters.ecosystemDisplayName
      },
      viewActorTypes () {
        return this.$store.getters.viewActorTypes
      },
      totalNewActorsCount () {
        return this.$store.state.actors.newActorCount
      },
      totalNewProductsCount () {
        return this.$store.state.actors.newProductCount
      },
      displayChallenges () {
        return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges
      },
      totalNewChallengesCount () {
        return this.$store.state.challenges.newChallengesCount
      },
      totalNewNotificationsCount () {
        return this.$store.state.notifications.newNotificationsCount
      },
      displayAnnouncements () {
        return this.$store.getters.canUsePublicAnnouncements && this.$store.getters.areMessageBoardsEnabled
      },
      displayEvents () {
        return this.displayAnnouncements
      },
      intelligenceCtaUpgradeText () {
        if (this.showIntelligenceUpgradeCTA) {
          return this.config.packageCTAs.intelligence.text || this.$t('default_intelligence_upgrade_cta', { upgrade: 'upgrade' })
        }
        return ''
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      showIntelligenceUpgradeCTA () {
        return this.isLoggedIn && !this.displayExploreContent && this.config.packageCTAs && this.config.packageCTAs.intelligence && this.config.packageCTAs.intelligence.enabled
      },
      displayExploreContent () {
        const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

        return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
      },
      displayKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
      },
      config () {
        return this.$store.state.config
      },
      detailActor () {
        return this.$store.getters.detailActor
      },
      shouldSeeChallenges () {
        if (this.$store.getters.isMember) {
          return true
        }

        return this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
      },
    },
    created () {
      if (this.hasBlurredSecondTopBar) {
        this.backgroundColorStyle = 'rgba(218, 225, 218, .5)'
        this.borderBottomStyle = 'none'
      }
    },
    methods: {
      goToHighlightedSpottingAreaDetail () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.TOP_BAR_BUTTON, this.highlightedSpottingArea.id)
        this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
      },
      trackHeapEvent,
      checkIsActive (route) {
        if (this.$route.name === 'actor-detail') {
          if (this.detailActor) {
            if (route === 'products') {
              return this.detailActor.actor_type === 'Product'
            }

            if (route === 'actors') {
              return this.detailActor.actor_type !== 'Product'
            }
          }
        } else {
          // make sure that not both nav items are active when in Spotting areas > actors
          if (route === 'actors' && this.$route.path.indexOf('spotting-areas') >= 0) {
            return false
          }

          return this.$route.path.indexOf(route) >= 0
        }
      },
    },
    mixins: [
      UiMixin,
      TranslationsMixin,
      NavigationMixin,
      OnboardingMixin,
      CommunityMixin
    ],
    components: {
      Breadcrumbs,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .taxonomy-dropdown-overlay {
    height: calc(100vh - #{$second-top-bar-height});
    top: $second-top-bar-height + $top-bar-height;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .second-top-bar {
    .top-bar__links-parent {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      max-width: 70%;
    }

    .top-bar__links {
      display: flex;
      overflow: visible;
      width: 100%;
      justify-content: flex-start;
      align-items: baseline;

      &:last-child {
        margin-right: 15px;
      }

      .button {
        padding: 18px 10px 18px;
        margin-right: 0;
      }

      :deep(.button__label) {
        color: black;
        text-transform: uppercase;
      }

      .button--minimal:focus {
        // override !important in focus-state.scss
        box-shadow: none !important;
      }

      .second-top-bar__link-active {
        border-bottom: 3px solid var(--primary-community);
        padding-bottom: 15px;
      }
    }
  }

  .second-top-bar-parent {
    top: $top-bar-height;
  }

  .second-top-bar-parent-blurred {
    position: absolute;
    top: 75px;
    z-index: 140; // Should be less than onboarding login screen
    width: 100%;
    background-color: rgba(218, 225, 218, .5);
    border-bottom: none;
  }

  .highlight-spotting-area-button {
    color: white !important;
    text-transform: none !important;
    margin-left: 4px !important;

    :deep(.button__label) {
      font-weight: bold !important;
      color: white !important;
      text-transform: none !important;
    }
  }

  @media screen and (max-width: 1650px) {
    .highlight-spotting-area-button {
      margin-right: 20px !important;
      margin-left: 5px !important;
    }

    .top-bar__links-parent {
      max-width: 100% !important;
    }

    .top-bar__links {
      &:last-child {
        margin-right: 5px !important;
      }
    }
  }
</style>
